import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { settingsTypeUrl, partnerUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const ListPartner = () => {
  const [loading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState([]);

  function readPartner() {
    axios.get(`${partnerUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPartnerData(response.data);
    });
  }

  useEffect(() => {
    readPartner();
  }, []);
  return (
    <div>
      <Card>
        <div className=" overflow-x-auto">
          <table className="min-w-full leading-normal w-fullxx">
            <thead>
              <tr>
                <td>ID</td>
                <td>FullName</td>
                <td>Email</td>
                <td>Phone</td>
                <td>Income</td>
                <td>BankNo</td>
                <td>BankName</td>
                <td>Bank Account</td>
                {/*               
              
              <th></th> */}
              </tr>
            </thead>
            <tbody>
              {partnerData.map((item, index) => (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>
                    {item?.firstname} {item?.lastname}
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.phone}</td>
                  <td>{item?.incomeperc}</td>
                  <td>{item?.bankno}</td>
                  <td>{item?.bankname}</td>
                  <td>{item?.bankacname}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default ListPartner;
