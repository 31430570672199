import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  govUrl,
  pvUrl,
  uUrl,
  imgGovUrl,
  imgPvUrl,
  imgUnUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddProvider = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      scope: "",
      url: "",
      agency: "",
    },
  });
  //govadvert id,photo,scope,url,agency,status
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [locations, setLocations] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readLocation() {
    axios.get(`${govUrl}location.php`).then(function (response) {
      console.log(response.data);
      setLocations(response.data);
    });
  }
  useEffect(() => {
    readLocation();
  }, []);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("scope", data.scope);
    formData.append("url", data.url);
    formData.append("agency", data.agency);
    setLoading(true);
    //govadvert id,photo,scope,url,agency,status
    axios.post(`${pvUrl}add.php`, formData).then(function (response) {
      console.log(response.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
      //locations
    });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Scope</label>
            <select
              {...register("scope", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              <option value="0">All</option>
              {locations.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.thName}
                  {item.enName ? `(${item.enName})` : ""}-{item.id}
                </option>
              ))}
            </select>
            {errors.scope && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Select Scope
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Url Web site</label>
            <input
              type="url"
              name="url"
              {...register("url", {
                required: true,
              })}
            />
            {errors.url && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Url Web site
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Agency</label>
            <input
              type="text"
              name="agency"
              {...register("agency", {
                required: true,
              })}
            />
            {errors.agency && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Agency
              </div>
            )}
          </div>

          {/*       scope: "",
  url: "",
  agency: "", */}
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddProvider;
