import React from "react";
import { WelcomeRow } from "../components";
import { Outlet, Link } from "react-router-dom";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import { FiList } from "react-icons/fi";

const GalleryOffice = () => {
  return (
    <>
      <WelcomeRow />
      <div className="mb-6 items-center">
        <div className="flex items-center justify-end gap-1  rounded-md overflow-hidden">
          <Link to={""} className="btntrans">
            <FiList size={22} />
          </Link>
          <Link to={"addgallery"} className="btnblue">
            <BsPlusCircle size={22} />
          </Link>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default GalleryOffice;
