import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import { settingsTypeUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";

import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";
import Swal from "sweetalert2";

import { useNavigate, useParams } from "react-router-dom";

const EditType = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [typename, setTypename] = useState("");
  const [description, setDescription] = useState("");
  const [flag, setFlag] = useState("");

  //accounttype id,typename,description,flag,created_at
  function readData(id) {
    axios
      .get(`${settingsTypeUrl}readactypebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setTypename(response.data?.typename);
        setDescription(response.data?.description);
        setFlag(response.data?.flag);
      });
  }

  useEffect(() => {
    readData(id);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${settingsTypeUrl}updateactype.php`, {
        idEdit: id,
        typename,
        description,
        flag,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label>Type Name</label>
            <input
              type="text"
              value={typename}
              onChange={(e) => setTypename(e.target.value)}
            />
          </div>
          {/* accounttype id,typename,description,flag,created_at */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              Discription
            </label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              Flag
            </label>
            <input
              type="text"
              value={flag}
              onChange={(e) => setFlag(e.target.value)}
            />
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditType;
