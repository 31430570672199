import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
  gfIncomeUrl,
  pltype,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";

import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const EditIncome = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});

  const [incomeEName, setIncomeEName] = useState("");
  const [amountEPercent, setAmountEPercent] = useState("");
  const [incomeEAmount, setIncomeEAmount] = useState("");
  const [pltype, setPltype] = useState("");

  function readData(id) {
    axios.get(`${gfIncomeUrl}readbyid.php/${id}`).then(function (response) {
      console.log("GFIncome: ", response.data);
      setDatas(response.data);
      setIncomeEName(response.data?.incomeName);
      setAmountEPercent(response.data?.incomePercent);
      setIncomeEAmount(response.data?.incomeAmount);
      setPltype(response.data?.pltype);
    });
  }

  const [dataType, setDataType] = useState([]);

  function readTypeData() {
    axios.get(`${settingsTypeUrl}readactype.php`).then(function (response) {
      console.log(response.data);
      setDataType(response.data);
    });
  }

  useEffect(() => {
    readData(id);
    readTypeData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .put(`${gfIncomeUrl}update.php`, {
        idEdit: id,
        incomeEName,
        amountEPercent,
        incomeEAmount,
        pltype,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        setIncomeEName("");
        setAmountEPercent("");
        setIncomeEAmount("");
        setPltype("");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label>Income Type</label>
            <select
              value={pltype}
              onChange={(e) => setPltype(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {dataType.map((item, index) => (
                <option key={index} value={item.flag}>
                  {item.typename}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6">
            <label>Income:</label>
            <input
              type="text"
              value={incomeEName}
              onChange={(e) => setIncomeEName(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label>Percent(%):</label>
            <input
              type="text"
              value={amountEPercent}
              onChange={(e) => setAmountEPercent(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label>Amount:</label>
            <input
              type="text"
              value={incomeEAmount}
              onChange={(e) => setIncomeEAmount(e.target.value)}
            />
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditIncome;
