import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { officesGalleryUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddGallery = () => {
  //officesGalleryUrl
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedFile, setSectedFile] = useState(null);
  const [selectedTwFile, setSectedTwFile] = useState(null);
  const handleOnChange = (e) => {
    /*     console.log("----------");
        console.log(e.target.files[0]);
        console.log("----------"); */
    setSectedFile(e.target.files[0]);
  };

  const handleTwOnChange = (e) => {
    /*     console.log("----------");
        console.log(e.target.files[0]);
        console.log("----------"); */
    setSectedTwFile(e.target.files[0]);
  };
  //office_gallery id,title,photo_1,photo_2,created_at
  const handleOnSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("fileTwData", selectedTwFile);
    formData.append("title", title);
    axios
      .post(`${officesGalleryUrl}add.php`, formData)
      .then(function (response) {
        console.log(response.data);
        setTitle("");
        setSectedFile(null);
        setSectedTwFile(null);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="my-2">
            <label>Photo 1</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="my-2">
            <label>Photo 2</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleTwOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddGallery;
