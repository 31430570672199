import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css

// import ReactPlayer from "react-player";
import ReactPlayer from "react-player/lazy";

const EditVideo = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [thName, setThName] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChangeCb = () => {
    setIsChecked(!isChecked);
  };

  function readData(id) {
    axios
      .get(`${videoSalepageUrl}readbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setThName(response.data?.thName);
        setIsChecked(response.data?.isDisplay == "1" ? true : false);
        // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
      });
  }

  useEffect(() => {
    readData(id);
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const isShow = isChecked ? "1" : "0";
    formData.append("fileToUpload", selectedFile);
    formData.append("thName", thName);
    formData.append("isDisplay", isShow);
    formData.append("idEdit", id);
    setLoading(true);

    axios
      .post(`${videoSalepageUrl}update.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.video ? (
            <>
              {/*               <Player>
                <source src={`${videoSalepageFileUrl}${datas?.video}`} />
              </Player> */}
              {/* // Render a YouTube video player */}
              <ReactPlayer
                controls={true}
                url={`${videoSalepageFileUrl}${datas?.video}`}
                width="100%"
                height="100%"
              />
            </>
          ) : (
            "-"
          )}
        </div>
        {/*         <div>{`${videoSalepageFileUrl}${datas?.video}`}</div>
        <div>{id}</div>
        <div>{datas?.video}</div> */}
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Name</label>
            <input
              type="text"
              value={thName}
              onChange={(e) => setThName(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label>Video File</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              id="some_id"
              value="1"
              checked={isChecked}
              onChange={handleOnChangeCb}
              className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
            />
            <label htmlFor="some_id"> Display in SalePage</label>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditVideo;
