import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";
import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const ViewUser = () => {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);

  function readData() {
    axios.get(`${gfUserUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
    });
  }

  useEffect(() => {
    readData();
  }, []);

  const deleteData = (id) => {
    // ----
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${gfUserUrl}delete.php/${id}`).then(function (response) {
          readData();
          console.log(response.data);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        });
      }
    });
  };
  return (
    <div>
      {datas.map((item, index) => (
        <div key={index} className="">
          <Card>
            <div className="flex flex-col gap-2 md:flex-row  relative">
              <div className="mb-4 w-full">
                <div className="font-semibold">
                  <strong>Full name:</strong> {item.firstname}/{item.lastname}
                </div>
                <div className="text-sm">
                  <strong>Phone:</strong> {item.phone}
                </div>
                <div className="text-sm">
                  <strong>E-mail:</strong> {item.email}
                </div>
                <div className="text-sm">
                  <strong>Password:</strong> ({item.password})
                </div>
              </div>
              <div className="mt-2 md:w-1/2 flex justify-end items-end  absolute bottom-1 right-0">
                <button
                  onClick={() => deleteData(item.id)}
                  className="bg-red-500 mr-2 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                >
                  <IoTrashOutline size={22} />
                </button>
                <Link
                  to={`editgfuser/${item.id}`}
                  className="bg-green-500 hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                >
                  <HiMiniPencilSquare size={22} />
                </Link>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ViewUser;
