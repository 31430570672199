import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  officesHerosUrl,
  imgOfficeUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddHero = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      subtitle: "",
      description: "",
      titledownload: "",
      androiduser: "",
      iosuser: "",
    },
  });
  //  // title,subtitle,discription,titledownload,androiduser,iosuser
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("title", data.title);
    formData.append("subtitle", data.subtitle);
    formData.append("description", data.description);
    formData.append("titledownload", data.titledownload);
    formData.append("androiduser", data.androiduser);
    formData.append("iosuser", data.iosuser);
    setLoading(true);
    // title,subtitle,discription,titledownload,androiduser,iosuser
    axios.post(`${officesHerosUrl}add.php`, formData).then(function (response) {
      console.log(response.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              name="title"
              {...register("title", {
                required: true,
              })}
            />
            {errors.title && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Title
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Subtitle</label>
            <input
              type="text"
              name="subtitle"
              {...register("subtitle", {
                required: true,
              })}
            />
            {errors.subtitle && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Subtitle
              </div>
            )}
          </div>
          {/*  title,subtitle,discription,titledownload,androiduser,iosuser */}
          <div className="mb-2">
            <label>description</label>
            <input
              type="text"
              name="description"
              {...register("description", {
                required: true,
              })}
            />
            {errors.description && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Description
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Title Download</label>
            <input
              type="text"
              name="titledownload"
              {...register("titledownload", {
                required: true,
              })}
            />
            {errors.titledownload && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Title Download
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="mb-2">
              <label>Android User Download Url</label>
              <input
                type="url"
                name="androiduser"
                {...register("androiduser", {
                  required: true,
                })}
              />
              {errors.androiduser && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter Android User Download Url
                </div>
              )}
            </div>
            <div className="mb-2">
              <label>iOS User Download Url</label>
              <input
                type="url"
                name="iosuser"
                {...register("iosuser", {
                  required: true,
                })}
              />
              {errors.iosuser && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter iOS User Download Url
                </div>
              )}
            </div>
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddHero;
