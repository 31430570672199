import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const EditLogoCompany = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [componyName, setComponyName] = useState("");

  // laborlogo id,title,photo,discription,url,created_at
  //companylogo id,componyName,photo,created_at
  function readData(id) {
    axios
      .get(`${logoUrl}readcompanylogobyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setComponyName(response.data?.componyName);

        // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
      });
  }

  useEffect(() => {
    readData(id);
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("componyName", componyName);
    formData.append("idEdit", id);
    // laborlogo id,title,photo,discription,url,created_at
    setLoading(true);
    axios
      .post(`${logoUrl}updatecompanylogo.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      });
  };
  return (
    <>
      <div className="">
        <div className="">
          <div>
            {datas?.photo != null ? (
              <img
                src={`${imgLogoUrl}${datas?.photo}`}
                className="rounded-lg mb-4 md:h-[300px] object-cover"
              />
            ) : (
              "-"
            )}
          </div>
          {/* ----   const [componyName, setComponyName] = useState(""); */}
          <form onSubmit={handleOnSubmit}>
            <div className="mb-2">
              <label>ชื่อหน่วยงานออกโลโก้</label>
              <input
                type="text"
                value={componyName}
                onChange={(e) => setComponyName(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>

            <div className="my-2">
              <label>รูปโลโก้</label>
              <div className="flex flex-col items-start">
                <input type="file" onChange={handleOnChange} />
              </div>
            </div>
            <div className="flex justify-end">
              <button disabled={loading} type="submit" className="btnblue">
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
          {/* ----  */}
        </div>
      </div>
    </>
  );
};

export default EditLogoCompany;
