import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  govUrl,
  pvUrl,
  uUrl,
  imgGovUrl,
  imgPvUrl,
  imgUnUrl,
  gfcompanyUrl,
  imgLogoUrl,
} from "../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../Card";
import Swal from "sweetalert2";

const EditCompany = () => {
  const { id } = useParams();
  const [userId, setUserId] = useState(21);
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [locations, setLocations] = useState([]);
  const [selectedFile, setSectedFile] = useState(null);
  const [incometype, setIncometype] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyLogText, setCompanyLogText] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  function readLocation() {
    axios.get(`${gfcompanyUrl}companytype.php`).then(function (response) {
      console.log(response.data);
      setLocations(response.data);
    });
  }
  function readData(id) {
    axios.get(`${gfcompanyUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setIncometype(response.data?.incometype);
      setCompanyLogo(response.data?.companyLogo);
      setCompanyLogText(response.data?.companyLogText);
      setCompanyName(response.data?.companyName);
      setCompanyAddress(response.data?.companyAddress);
      // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
    });
  }

  useEffect(() => {
    readData(id);
    readLocation();
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("incometype", incometype);
    // formData.append("companyLogo", data.companyLogo);
    formData.append("companyLogText", companyLogText);
    formData.append("companyName", companyName);
    formData.append("companyAddress", companyAddress);
    // formData.append("companyLocation", data.companyLocation);
    formData.append("userId", userId);
    formData.append("idEdit", id);
    setLoading(true);
    /*   scope: "",
  url: "",
  agency: "", */
    axios.post(`${gfcompanyUrl}update.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
  };
  return (
    <div className="w-full md:w-full lg:w-1/2 ml-auto">
      <Card>
        <div>
          {datas?.companyLogo != null ? (
            <img
              src={`${imgLogoUrl}${datas?.companyLogo}`}
              className="rounded-lg mb-4 md:h-[300px] object-cover"
            />
          ) : (
            "-"
          )}
        </div>

        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Income Type</label>
            <select
              value={incometype}
              onChange={(e) => setIncometype(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">มองเห็นทั้งหมด</option> */}
              {locations.map((item, index) => (
                <option key={index} value={item.typecode}>
                  {item.typename}(${item.typecode})
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2">
            <label>Company Name</label>
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Company Address</label>
            <input
              type="text"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Company Logo Text</label>
            <input
              type="text"
              value={companyLogText}
              onChange={(e) => setCompanyLogText(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label>Company Logo Image</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditCompany;
