import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  officesHerosUrl,
  officesPopularsUrl,
  imgOfficeUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddPopular = () => {
  //officesPopularsUrl
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      url: "",
    },
  });
  //office_popular_detail id,popularID,icon,title,discription,url,photo,created_at
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("url", data.url);
    setLoading(true);
    //office_popular_detail id,popularID,icon,title,discription,url,photo,created_at
    axios
      .post(`${officesPopularsUrl}add.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };

  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              name="title"
              {...register("title", {
                required: true,
              })}
            />
            {errors.title && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Title
              </div>
            )}
          </div>

          <div className="mb-2">
            <label>description</label>
            <input
              type="text"
              name="description"
              {...register("description", {
                required: true,
              })}
            />
            {errors.description && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Description
              </div>
            )}
          </div>
          {/*        popularID: "",
      title: "",
      description: "",
      url: "", */}
          <div className="mb-2">
            <label>Read More url</label>
            <input
              type="url"
              name="url"
              {...register("url", {
                required: true,
              })}
            />
            {errors.titledownload && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Read More url
              </div>
            )}
          </div>

          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddPopular;
