import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const EditLocation = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [thName, setThName] = useState("");
  const [enName, setEnName] = useState("");
  const [description, setDescription] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  /*   thName: "",
  enName: "",
  description: "",
  zipCode: "",
  latitude: "",
  longitude: "", */
  //appinfo id,appName,appDiscription,photo,country
  //accounttype id,typename,description,flag,created_at
  function readData(id) {
    axios
      .get(`${locationUrl}readlocationbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setThName(response.data?.thName);
        setEnName(response.data?.enName);
        setDescription(response.data?.description);
        setZipCode(response.data?.zipCode);
        setLatitude(response.data?.latitude);
        setLongitude(response.data?.longitude);
        // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
      });
  }

  useEffect(() => {
    readData(id);
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("thName", thName);
    formData.append("enName", enName);
    formData.append("description", description);
    formData.append("zipCode", zipCode);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("idEdit", id);
    setLoading(true);
    /*     idEdit: id,
    appName,
    description,
    country, */
    // console.log(thName, enName);

    axios
      .post(`${locationUrl}updatelocation.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.photo !== "" ? (
            <img
              src={`${imglocationUrl}${datas?.photo}`}
              className="rounded-lg mb-4 h-[300px]"
            />
          ) : (
            "-"
          )}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Name</label>
            <input
              type="text"
              value={thName}
              onChange={(e) => setThName(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>En Name</label>
            <input
              type="text"
              value={enName}
              onChange={(e) => setEnName(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Discription</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>zipCode</label>
            <input
              type="text"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="mb-2">
              <label>Latitude</label>
              <input
                type="text"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>longitude</label>
              <input
                type="text"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
            </div>
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditLocation;
