import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  officesFaqUrl,
  imgFaqUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};
const EditFaq = () => {
  //officesFaqUrl,imgFaqUrl
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  //   office_faq id,title,description,photo,created_at
  function readData(id) {
    axios.get(`${officesFaqUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setTitle(response.data?.title);
      setDescription(response.data?.description);
      // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
    });
  }

  useEffect(() => {
    readData(id);
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };
  //   office_faq id,title,description,photo,created_at
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("idEdit", id);
    setLoading(true);

    axios
      .post(`${officesFaqUrl}update.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        //datas?.photo != undefined ||
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full">
      <Card>
        <div>
          {datas?.photo !== "" ? (
            <img
              src={`${imgFaqUrl}${datas?.photo}`}
              className="rounded-lg mb-4 md:h-[300px] object-cover"
            />
          ) : null}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-2 h-screen">
            <ReactQuill
              modules={modules}
              theme="snow"
              value={description}
              onChange={setDescription}
              className="h-4/5"
            />
          </div>
          {/*           <div className="mb-2">
            <label>Description</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div> */}
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditFaq;
