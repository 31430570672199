import React from "react";

const IncomeBox = ({ text, total, bg, color, icon }) => {
  return (
    <div
      className={`${bg} grow flex justify-between items-center gap-2 text-primary p-5 rounded-xl border border-gray-100`}
    >
      <div>
        <h2 className={`${color} font-bold text-2xl leading-4`}>{total}</h2>
        <h3 className={`${color} text-lg`}>{text}</h3>
      </div>
      {icon}
    </div>
  );
};

export default IncomeBox;
