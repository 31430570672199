import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const ViewLogoCompany = () => {
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${logoUrl}readcompanylogo.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${logoUrl}deletecompanylogo.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readAppData();
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            ข้อมูลโลโก้
          </h4>
          {/* ----------  */}
          <div className="max-w-full overflow-x-auto">
            {/* ---------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[200px] w-full font-medium text-black dark:text-white">
                    รูปโลโก้
                  </th>
                  <th className="min-w-[250px] font-medium text-black dark:text-white">
                    ชื่อหน่วยงานออกโลโก้
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataApp.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item.photo !== "" ? (
                        <img
                          src={`${imgLogoUrl}${item.photo}`}
                          className="rounded-lg w-[100px]"
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <p className="text-sm"> {item?.componyName}</p>
                    </td>

                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        <button
                          onClick={() => deleteData(item?.id)}
                          className="hover:text-primary"
                        >
                          <IoTrashOutline size={22} />
                        </button>
                        <Link to={`editcomlogo/${item?.id}`} className="">
                          <HiMiniPencilSquare size={22} />
                        </Link>
                        {/*                         <Link to={`addsalary/${item?.id}`} className="">
                      บันทึกเงินเดือน
                    </Link> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* ---------  */}
          </div>
          {/*           <div>
        {dataApp.map((item, index) => (
          <div key={index}>
            <div className="flex flex-col gap-2 md:flex-row  relative">
              <div className="w-full flex flex-col gap-4 md:flex-row">
                {item.photo !== "" ? (
                  <img
                    src={`${imgLogoUrl}${item.photo}`}
                    className="rounded-lg w-[100px]"
                  />
                ) : (
                  "-"
                )}
                <div className="w-full">
                  <h2 className="font-semibold">{item?.discription}</h2>
                </div>
              </div>

              <div className="mt-2 md:w-1/2 flex justify-end items-end absolute top-1 right-0">
                <button
                  onClick={() => deleteData(item.id)}
                  className="bg-red-500 mr-4 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                >
                  <IoTrashOutline size={22} />
                </button>

                <Link
                  to={`editgov/${item.id}`}
                  className="mr-1 bg-blue-500 hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                >
                  <HiMiniPencilSquare size={22} />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div> */}
          {/* ----------  */}
        </div>
      </div>
    </>
  );
};

export default ViewLogoCompany;
