import React from "react";
import { WelcomeRow } from "../components";
import { Outlet, Link } from "react-router-dom";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import { FiList } from "react-icons/fi";

const PolicyTerm = () => {
  return (
    <>
      <WelcomeRow />
      <div className="mb-6 items-center">
        <div className="flex items-center justify-end gap-1  rounded-md overflow-hidden">
          <Link
            to={""}
            className="bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-1.5 px-8 border hover:border-transparent rounded"
          >
            <FiList size={22} />
          </Link>
          <Link
            to={"addpolicy"}
            className="bg-blue-500 hover:bg-blue-700 text-white border rounded w-[100px] py-1 pl-2 flex gap-2 items-center"
          >
            <BsPlusCircle size={22} /> Policy
          </Link>
          <Link
            to={"addterm"}
            className="bg-green-500 hover:bg-green-700 text-white border rounded w-[100px] py-1 pl-2 flex gap-2 items-center mr-2"
          >
            <BsPlusCircle size={22} /> Term
          </Link>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default PolicyTerm;
