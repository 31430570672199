import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { settingsTypeUrl, appinfoUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddAppInfo = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      appName: "",
      appDiscription: "",
      country: "",
    },
  });
  //appinfo id,appName,appDiscription,photo,country
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("appName", data.appName);
    formData.append("appDiscription", data.appDiscription);
    formData.append("country", data.country);
    setLoading(true);

    axios
      .post(`${appinfoUrl}addappinfo.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>App Name</label>
            <input
              type="text"
              name="appName"
              {...register("appName", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.appName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter App Name
              </div>
            )}
          </div>
          {/* appName: "", appDiscription: "", country: "", */}
          <div className="mb-2">
            <label>App Discription</label>
            <input
              type="text"
              name="appDiscription"
              {...register("appDiscription", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.appDiscription && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter App Discription
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Country</label>
            <input
              type="text"
              name="country"
              {...register("country", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.country && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Country
              </div>
            )}
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddAppInfo;
