import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  minorOccUrl,
  imgminorOccUrl,
  imgmainOccUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddMinorOcc = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      thName: "",
      enName: "",
      mid: "",
    },
  });
  //mainoccupation id,thName,enName,photo,created_at
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [occupations, setOccupations] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readOccupation() {
    axios.get(`${mainOccUrl}readmainocc.php`).then(function (response) {
      console.log(response.data);
      setOccupations(response.data);
    });
  }
  useEffect(() => {
    readOccupation();
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("thName", data.thName);
    formData.append("enName", data.enName);
    formData.append("mid", data.mid);
    setLoading(true);

    axios
      .post(`${minorOccUrl}addminorocc.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Main Occupation</label>
            <select
              {...register("mid", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {occupations.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.thName}/{item.enName}
                </option>
              ))}
            </select>
            {errors.mid && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Main Ocupation
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Name</label>
            <input
              type="text"
              name="thName"
              {...register("thName", {
                required: true,
              })}
            />
            {errors.thName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Name
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>EN Name</label>
            <input
              type="text"
              name="enName"
              {...register("enName", {
                required: true,
              })}
            />
            {errors.enName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter En Name
              </div>
            )}
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddMinorOcc;
