import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import UploadImg from "../../assets/img/icons/upload.png";
import { blogUrl, imgBlogUrl } from "../../utils/baseUrl";
import Card from "./../Card";
const AddBlog = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      youtube: "",
    },
  });
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSectedFile] = useState(null);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
    setFileName(URL.createObjectURL(e.target.files[0])); //.name
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("youtube", data.youtube);
    setLoading(true);

    axios.post(`${blogUrl}add.php`, formData).then(function (response) {
      console.log(response.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
      setFileName(null);
      reset();

      setLoading(false); // Stop loading
    });
  };
  return (
    <div className="w-full ">
      <Card>
        <div className="grid grid-cols-12 gap-6">
          {/* ------  */}
          <div className="mb-6 bg-white p-8 rounded-xl col-span-12 md:col-span-6 xl:col-span-8  ">
            {/* ------ */}

            <div className="mb-6 text-center md:text-left">
              <label
                htmlFor="productImage"
                className="flex flex-col items-center gap-4 py-1 hover:cursor-pointer"
              >
                {/* <p className="mb-2 text-base text-black">Upload Image</p> {`${imgUrl}${selectedFile}`}imgUrl*/}
                <div className="text-left">
                  {fileName !== null ? (
                    <>
                      <img
                        className={`w-full h-[${
                          windowSize[0] * 0.5
                        }px] rounded-xl object-cover`}
                        src={fileName}
                        alt=""
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className="w-[100px] h-auto"
                        src={UploadImg}
                        alt=""
                      />
                    </>
                  )}
                </div>
                {/* <span className="w-full inline-block mb-3">Image size must be less than 5Mb</span> */}
                <div className="">
                  <input
                    type="file"
                    onChange={handleOnChange}
                    id="productImage"
                    className="hidden"
                  />
                  คลิกอัพโหลดรูปภาพ
                </div>
              </label>
            </div>
            {/* ----------- */}
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <div className="mb-6">
                <label className="mb-0 text-base text-black">
                  ชื่อเรื่อง/หัวข้อ
                </label>
                <input
                  name="title"
                  {...register("title", {
                    required: true,
                    // maxLength: 10,
                  })}
                  className="input w-full h-[44px] rounded-sm border border-gray6 px-6 text-base"
                  type="text"
                  placeholder=""
                />
                {errors.title && (
                  <div className="my-3 text-normal text-danger text-xs italic">
                    ป้อนชื่อเรื่อง/หัวข้อ
                  </div>
                )}
              </div>
              <div className="mb-6">
                <label className="mb-0 text-base text-black">รายละเอียด</label>
                <textarea
                  {...register("description", {
                    required: true,
                    // maxLength: 10,
                  })}
                  className="input rounded-sm border border-gray6 px-6 text-base h-[150px] w-full py-3 resize-none"
                  placeholder=""
                  defaultValue={""}
                />
                {errors.description && (
                  <div className="my-3 text-normal text-danger text-xs italic">
                    รายละเอียด
                  </div>
                )}
              </div>
              <div className="mb-6">
                <label className="mb-0 text-base text-black">
                  Youtube Link
                </label>
                <input
                  name="youtube"
                  {...register("youtube", {
                    // required: true,
                    // maxLength: 10,
                  })}
                  className="input w-full h-[44px] rounded-sm border border-gray6 px-6 text-base"
                  type="url"
                  placeholder=""
                />
                {/*                 {errors.youtube && (
                  <div className="my-3 text-normal text-danger text-xs italic">
                    ป้อน Youtube
                  </div>
                )} */}
              </div>
              <button type="submit" className="btnblue">
                {loading ? <>กรุณารอ..</> : <>บันทึก</>}
              </button>
            </form>
            {/* ----------- */}

            {/* ------ */}
          </div>
          {/* ------  */}
        </div>
      </Card>
    </div>
  );
};

export default AddBlog;
