import React, { useState, useEffect } from "react";
import { WelcomeRow, IncomeBox, SalesStats } from "../components";
import { useNavigate } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import { FaRegMoneyBillAlt, FaShoppingBasket } from "react-icons/fa";
// import { IconName } from "react-icons/hi2";
// import { IconName } from "react-icons/io5";
import {
  FcBusinessman,
  FcComboChart,
  FcConferenceCall,
  FcInTransit,
} from "react-icons/fc";

const Dashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  useEffect(() => {
    /*     var user = localStorage.getItem("id");
    console.log("user: ", user); */
    var user = localStorage.getItem("id");
    if (user != null) {
      setUser(user);
      navigate("/");
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <WelcomeRow />
      {/* text, total, bg, color, icon */}
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
        <IncomeBox
          text={"Revenue"}
          total={"$100"}
          bg={"bg-fuchsia-500"}
          color={"text-white"}
          icon={<FcComboChart size={40} />}
        />
        <IncomeBox
          text={"Partner"}
          total={"+5"}
          bg={"bg-amber-500"}
          color={"text-white"}
          icon={<FcBusinessman size={40} />}
        />
        <IncomeBox
          text={"Distribute"}
          total={"+20"}
          bg={"bg-pink-500"}
          color={"text-white"}
          icon={<FcConferenceCall size={40} />}
        />
        <IncomeBox
          text={"Sales"}
          total={"+8"}
          bg={"bg-socialBlue"}
          color={"text-white"}
          icon={<FcInTransit size={40} />}
        />
      </div>
      <SalesStats />
      {/* Orders today */}
    </>
  );
};

export default Dashboard;
