import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
  gfIncomeUrl,
  yuSalepageUrl,
  pltype,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

//yuSalepageUrl
const AddYoutube = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      thName: "",
      url: "",
      // isDisplay: "",
    },
  });
  //salepageyoutube id,thName,url,created_at
  //C:\xampp\htdocs\appApl\settings\accounttype
  // console.log(`${settingsTypeUrl}addactype.php`);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const handleOnSubmit = (data) => {
    setLoading(true);

    axios
      .post(`${yuSalepageUrl}add.php`, {
        thName: data.thName,
        url: data.url,
        isDisplay: isChecked ? "1" : "0",
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        // toast.success("Successfully created!");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Description</label>
            <input
              type="text"
              name="thName"
              {...register("thName", {
                required: true,
              })}
            />
            {errors.thName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Description
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Link Youtube</label>
            <input
              type="url"
              {...register("url", {
                required: true,
              })}
            />
            {errors.url && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Link Youtube
              </div>
            )}
          </div>
          {/*           <div className="mb-2">
            <input
              type="checkbox"
              id="topping"
              name="topping"
              value="1"
              checked={isChecked}
              onChange={handleOnChange}
            />{" "}
            Display in Salepage {isChecked ? "1" : "0"}
          </div> */}
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              id="some_id"
              value="1"
              checked={isChecked}
              onChange={handleOnChange}
              className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
            />
            <label htmlFor="some_id"> Display in SalePage</label>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddYoutube;
