import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  minorOccUrl,
  imgminorOccUrl,
  imgmainOccUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const ViewMinorOcc = () => {
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${minorOccUrl}readminorocc.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${minorOccUrl}deleteminorocc.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readAppData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      }
    });
  };
  return (
    <div>
      {/* locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at */}
      {dataApp.map((item, index) => (
        <div key={index} className="">
          <Card>
            <div className="flex flex-col gap-2 md:flex-row relative">
              <div className="w-full  flex flex-col gap-4 md:flex-row">
                {item.photo !== "" ? (
                  <img
                    src={`${imgminorOccUrl}${item.photo}`}
                    className="rounded-lg w-[100px]"
                  />
                ) : (
                  "-"
                )}
                <h2>
                  {item?.thName}({item?.enName})
                </h2>
              </div>
              <div className="mt-2 flex justify-end items-end absolute top-1 right-0">
                <button
                  // onClick={() => confirmDelete(item.id)}
                  onClick={() => deleteData(item.id)}
                  className="bg-red-500 mr-4 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                >
                  <IoTrashOutline size={22} />
                </button>

                <Link
                  to={`editminorocc/${item.id}`}
                  className="mr-1 bg-blue-500 hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                >
                  <HiMiniPencilSquare size={22} />
                </Link>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ViewMinorOcc;
