import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
  gfIncomeUrl,
  yuSalepageUrl,
  pltype,
  mPackageUrl,
  sPackageUrl,
  imgPackageUrl,
  statusUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const EditSPackage = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [shortdetail, setShortdetail] = useState("");
  const [longdetail, setLongdetail] = useState("");
  const [price, setPrice] = useState("");
  const [period, setPeriod] = useState("");
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [pkfor, setPkfor] = useState(null);
  const [packageFor, setPackageFor] = useState([]);
  const [selectedFile, setSectedFile] = useState(null);
  function readUnit() {
    axios.get(`${mPackageUrl}readunit.php`).then(function (response) {
      console.log(response.data);
      setUnits(response.data);
    });
  }
  function readPkFor() {
    axios.get(`${statusUrl}readpkfor.php`).then(function (response) {
      console.log(response.data);
      setPackageFor(response.data);
    });
  }
  /*   useEffect(() => {
    readUnit();
    readPkFor();
  }, []); */
  //accounttype id,typename,description,flag,created_at
  function readData(id) {
    axios.get(`${sPackageUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setTitle(response.data?.title);
      setShortdetail(response.data?.shortdetail);
      setLongdetail(response.data?.longdetail);
      setPrice(response.data?.price);
      setPeriod(response.data?.period);
      setUnit(response.data?.unit);
      setPkfor(response.data?.packagefor);
      /*   title: "",
  shortdetail: "",
  longdetail: "",
  price: "",
  period: "",
  unit: "", */
    });
  }

  useEffect(() => {
    readData(id);
    readUnit();
    readPkFor();
  }, []);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("title", title);
    formData.append("shortdetail", shortdetail);
    formData.append("longdetail", longdetail);
    formData.append("price", price);
    formData.append("period", period);
    formData.append("unit", unit);
    formData.append("packagefor", pkfor);
    formData.append("idEdit", id);
    setLoading(true);

    axios.post(`${sPackageUrl}update.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
    /*    axios
      .post(`${sPackageUrl}update.php`, {
        idEdit: id,
        title,
        shortdetail,
        longdetail,
        price,
        period,
        unit,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      }); */
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit}>
          <div>
            {datas?.photo != null ? (
              <img
                src={`${imgPackageUrl}${datas?.photo}`}
                className="rounded-lg mb-4 md:h-[300px]"
              />
            ) : (
              "-"
            )}
          </div>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Short Discription</label>
            <input
              type="text"
              value={shortdetail}
              onChange={(e) => setShortdetail(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Long Discription</label>
            <input
              type="text"
              value={longdetail}
              onChange={(e) => setLongdetail(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Price</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-2">
              <label>Period</label>
              <input
                type="number"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              />
            </div>

            <div className="mb-2">
              <label>อาชีพหลัก</label>
              <select
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              >
                <option value=""></option>
                {/* <option value="0">ไม่ระบุ</option> */}
                {units.map((item, index) => (
                  <option key={index} value={item.unitname}>
                    {item.unitname}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <label>For Privider/User</label>
              <select
                value={pkfor}
                onChange={(e) => setPkfor(e.target.value)}
                className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              >
                <option value=""></option>
                {/* <option value="0">ไม่ระบุ</option> */}
                {packageFor.map((item, index) => (
                  <option key={index} value={item.flag}>
                    {item.title}( {item.flag})
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditSPackage;
