import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import { bankUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";

import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";
import Swal from "sweetalert2";

import { useNavigate, useParams } from "react-router-dom";

const EditBank = () => {
  // bankUrl
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [thName, setThName] = useState("");
  const [enName, setEnName] = useState("");

  //// bankname id,thName,enName,created_at
  function readData(id) {
    axios.get(`${bankUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setThName(response.data?.thName);
      setEnName(response.data?.enName);
    });
  }

  useEffect(() => {
    readData(id);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${bankUrl}update.php`, {
        idEdit: id,
        thName,
        enName,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label>Name</label>
            <input
              type="text"
              value={thName}
              onChange={(e) => setThName(e.target.value)}
            />
          </div>
          {/* accounttype id,typename,description,flag,created_at */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              En Name
            </label>
            <input
              type="text"
              value={enName}
              onChange={(e) => setEnName(e.target.value)}
            />
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditBank;
