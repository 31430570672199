import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddLocation = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      thName: "",
      enName: "",
      description: "",
      zipCode: "",
      latitude: "",
      longitude: "",
    },
  });
  //appinfo id,appName,appDiscription,photo,country
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("thName", data.thName);
    formData.append("enName", data.enName);
    formData.append("description", data.description);
    formData.append("zipCode", data.zipCode);
    formData.append("latitude", data.latitude);
    formData.append("longitude", data.longitude);
    setLoading(true);

    axios
      .post(`${locationUrl}addlocation.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Name</label>
            <input
              type="text"
              name="thName"
              {...register("thName", {
                required: true,
              })}
            />
            {errors.thName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Name
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>EN Name</label>
            <input
              type="text"
              name="enName"
              {...register("enName", {
                required: true,
              })}
            />
            {errors.enName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter En Name
              </div>
            )}
          </div>
          {/*           thName: "", enName: "", description: "", zipCode: "", latitude: "",
          longitude: "", */}
          <div className="mb-2">
            <label>description</label>
            <input
              type="text"
              name="description"
              {...register("description", {
                required: true,
              })}
            />
            {errors.description && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Description
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>zipCode</label>
            <input
              type="text"
              name="zipCode"
              {...register("zipCode", {
                required: true,
              })}
            />
            {errors.zipCode && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter zipCode
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="mb-2">
              <label>latitude</label>
              <input
                type="text"
                name="latitude"
                {...register("latitude", {
                  required: true,
                })}
              />
              {errors.latitude && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter latitude
                </div>
              )}
            </div>
            <div className="mb-2">
              <label>longitude</label>
              <input
                type="text"
                name="longitude"
                {...register("longitude", {
                  required: true,
                })}
              />
              {errors.longitude && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter longitude
                </div>
              )}
            </div>
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddLocation;
