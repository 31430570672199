import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
  officesHerosUrl,
  officesCompanyUrl,
  officesPopularsHeaderUrl,
  officesPTUrl,
  imgOfficeUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";
import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import { FiSave } from "react-icons/fi";

const ViewPT = () => {
  //officesPTUrl
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);
  const [dataTApp, setTDataApp] = useState([]);

  function readAppData() {
    axios.get(`${officesPTUrl}readP.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }
  function readAppTData() {
    axios.get(`${officesPTUrl}readT.php`).then(function (response) {
      console.log(response.data);
      setTDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
    readAppTData();
  }, []);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${officesPTUrl}deleteP.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readAppData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      }
    });
  };
  const deleteTData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${officesPTUrl}deleteT.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readAppData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      }
    });
  };
  return (
    <>
      <div>
        {/* // title,subtitle,discription,titledownload,androiduser,iosuser */}
        <h2 className="ml-1">Privacy policy</h2>
        {dataApp.map((item, index) => (
          <div key={index} className="">
            <Card>
              <div className="flex flex-col gap-2 md:flex-row  relative">
                <div className="w-full  flex flex-col gap-4 md:flex-row">
                  <div className="w-full">
                    <p
                      className="mb-4 text-sm"
                      dangerouslySetInnerHTML={{
                        __html: item.content.slice(0, 100) + "...",
                      }}
                    ></p>
                    {item?.urlfiledownload ? (
                      <>
                        <p className="text-sm">
                          <a
                            href={`${imgOfficeUrl}${item?.urlfiledownload}`}
                            target="_blank"
                            className="bg-blue-500 hover:bg-blue-700 text-white border rounded w-[150px] py-1 pl-2 flex gap-2 items-center"
                          >
                            <BsDownload size={22} /> Download pdf
                          </a>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-sm text-red-500 font-bold">
                          No Pdf File!
                        </p>
                      </>
                    )}

                    {/* <p className="text-sm">{item?.description}</p> */}
                    {/* <p className="text-sm">{item?.titledownload}</p> */}
                    {/*               <p className="text-sm">
        {item?.latitude}/{item?.longitude}
      </p> */}
                  </div>
                </div>
                <div className="mt-2 md:w-1/2 flex justify-end items-end  absolute top-1 right-0">
                  <button
                    // onClick={() => confirmDelete(item.id)}
                    onClick={() => deleteData(item.id)}
                    className="bg-red-500 mr-4 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    <IoTrashOutline size={22} />
                  </button>

                  <Link
                    to={`editpolicy/${item.id}`}
                    className="mr-1 bg-blue-500 hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                  >
                    <HiMiniPencilSquare size={22} />
                  </Link>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
      {/* <hr /> */}
      <div>
        {/* // title,subtitle,discription,titledownload,androiduser,iosuser */}
        <h2 className="ml-1">Term of service</h2>
        {dataTApp.map((item, index) => (
          <div key={index} className="">
            <Card>
              <div className="flex flex-col gap-2 md:flex-row  relative">
                <div className="w-full  flex flex-col gap-4 md:flex-row">
                  <div className="w-full">
                    <p
                      className="mb-4 text-sm"
                      dangerouslySetInnerHTML={{
                        __html: item.content.slice(0, 100) + "...",
                      }}
                    ></p>
                    {item?.urlfiledownload ? (
                      <>
                        <p className="text-sm">
                          <a
                            href={`${imgOfficeUrl}${item?.urlfiledownload}`}
                            target="_blank"
                            className="bg-blue-500 hover:bg-blue-700 text-white border rounded w-[150px] py-1 pl-2 flex gap-2 items-center"
                          >
                            <BsDownload size={22} /> Download pdf
                          </a>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-sm text-red-500 font-bold">
                          No Pdf File!
                        </p>
                      </>
                    )}

                    {/* <p className="text-sm">{item?.description}</p> */}
                    {/* <p className="text-sm">{item?.titledownload}</p> */}
                    {/*               <p className="text-sm">
        {item?.latitude}/{item?.longitude}
      </p> */}
                  </div>
                </div>
                <div className="mt-2 md:w-1/2 flex justify-end items-end  absolute top-1 right-0">
                  <button
                    // onClick={() => confirmDelete(item.id)}
                    onClick={() => deleteTData(item.id)}
                    className="bg-red-500 mr-4 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    <IoTrashOutline size={22} />
                  </button>

                  <Link
                    to={`editterm/${item.id}`}
                    className="mr-1 bg-blue-500 hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                  >
                    <HiMiniPencilSquare size={22} />
                  </Link>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

export default ViewPT;
