import React from "react";
import Card from "./../Card";
const ViewBlogContent = () => {
  return (
    <div className="w-full ">
      <Card></Card>
    </div>
  );
};

export default ViewBlogContent;
