import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  gfcompanyUrl,
  introduceUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const AddIntroduce = () => {
  //introduceUrl
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      income: "",
    },
  });
  // partner id,firstname,lastname,phone,email,password,incomeperc,ispolicy,pltype,created_at
  // mpackage id,title,shortdetail,longdetail,price,period,unit,created_at
  //salepageyoutube id,thName,url,created_at
  //C:\xampp\htdocs\appApl\settings\accounttype
  // console.log(`${settingsTypeUrl}addactype.php`);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState({});
  const [isEmail, setIsEmail] = useState(false);
  const [dataType, setDataType] = useState([]);

  function readTypeData() {
    axios.get(`${introduceUrl}readincome.php`).then(function (response) {
      console.log(response.data);
      setDataType(response.data);
    });
  }

  useEffect(() => {
    readTypeData();
  }, []);
  // const [units, setUnits] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  /*   function readUnit() {
    axios.get(`${mPackageUrl}readunit.php`).then(function (response) {
      console.log(response.data);
      setUnits(response.data);
    });
  }
  useEffect(() => {
    readUnit();
  }, []); */
  const handleOnSubmit = (data) => {
    setLoading(true);

    axios
      .post(`${introduceUrl}checkemail.php`, {
        // pid: userId,
        email: data.email,
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === 1) {
          setIsEmail(true);
          setLoading(false);
          setUser(response.data);
        } else {
          //-------------
          axios
            .post(`${introduceUrl}add.php`, {
              firstname: data.firstname,
              lastname: data.lastname,
              phone: data.phone,
              email: data.email,
              password: data.password,
              income: data.income,
            })
            .then(function (response) {
              console.log(response.data);
              reset();
              setIsEmail(false);
              // toast.success("Successfully created!");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
              setLoading(false); // Stop loading
            });
          //-------------else
        }
      });
    //firstname,lastname,phone,email,password,pltype
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        {/* <h2>Add New Partner</h2> */}
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Firstname</label>
            <input
              type="text"
              name="firstname"
              {...register("firstname", {
                required: true,
              })}
            />
            {errors.firstname && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter firstname
              </div>
            )}
          </div>
          {/* //firstname,lastname,phone,email,password,pltype */}
          <div className="mb-2">
            <label>Lastname</label>
            <input
              type="text"
              name="lastname"
              {...register("lastname", {
                required: true,
              })}
            />
            {errors.lastname && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter lastname
              </div>
            )}
          </div>

          <div className="mb-2">
            <label>Phone</label>
            <input
              type="tel"
              name="phone"
              {...register("phone", {
                required: true,
              })}
            />
            {errors.phone && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter phone
              </div>
            )}
          </div>

          <div className="mb-2">
            <label>Email</label>
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
              })}
            />
            {errors.email && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter email
              </div>
            )}
            {isEmail && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Account already exists”, it means that the email address you're
                trying to add, has been registered as an account already
              </div>
            )}
          </div>
          {/* //firstname,lastname,phone,email,password,pltype */}
          <div className="mb-2">
            <label>Password</label>
            <input
              type="password"
              name="password"
              {...register("password", {
                required: true,
              })}
            />
            {errors.password && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter password
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Income</label>
            <select
              {...register("income", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {dataType.map((item, index) => (
                <option key={index} value={item.incomePercent}>
                  {item.incomeName}( {item.incomePercent})
                </option>
              ))}
            </select>
            {errors.income && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Select Income
              </div>
            )}
          </div>
          {/* ----  */}

          {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddIntroduce;
