import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
  gfIncomeUrl,
  pltype,
  gfcompanyUrl,
} from "../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const AddCompany = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      incometype: "",
      companyLogo: "",
      companyLogText: "",
      companyName: "",
      companyAddress: "",
      // companyLocation: "",
    },
  });
  //company id,userId,incometype,companyLogo,companyLogText,companyName,companyAddress,companyLocation
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [locations, setLocations] = useState([]);
  const [userId, setUserId] = useState(21);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readLocation() {
    axios.get(`${gfcompanyUrl}companytype.php`).then(function (response) {
      console.log(response.data);
      setLocations(response.data);
    });
  }
  useEffect(() => {
    readLocation();
  }, []);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("incometype", data.incometype);
    // formData.append("companyLogo", data.companyLogo);
    formData.append("companyLogText", data.companyLogText);
    formData.append("companyName", data.companyName);
    formData.append("companyAddress", data.companyAddress);
    // formData.append("companyLocation", data.companyLocation);
    formData.append("userId", userId);
    setLoading(true);
    //company id,userId,incometype,companyLogo,companyLogText,companyName,companyAddress,companyLocation
    axios.post(`${gfcompanyUrl}add.php`, formData).then(function (response) {
      console.log(response.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
      //locations
    });
  };
  // incometype id,typename,typecode
  return (
    <div className="w-full md:w-full lg:w-1/2 ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Income Type</label>
            <select
              {...register("incometype", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">มองเห็นทั้งหมด</option> */}
              {locations.map((item, index) => (
                <option key={index} value={item.typecode}>
                  {item.typename}(${item.typecode})
                </option>
              ))}
            </select>
            {errors.scope && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Select Income Type
              </div>
            )}
          </div>
          {/*           //company id,userId,incometype,companyLogo,
          companyLogText,companyName,companyAddress,companyLocation */}

          <div className="mb-2">
            <label>Company Name</label>
            <input
              type="text"
              name="companyName"
              {...register("companyName", {
                required: true,
              })}
            />
            {errors.agency && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Company Name
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Company Address</label>
            <input
              type="text"
              name="companyAddress"
              {...register("companyAddress", {
                required: true,
              })}
            />
            {errors.url && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Company Address
              </div>
            )}
          </div>
          {/*           <div className="mb-2">
            <label>Company Address</label>
            <input
              type="text"
              name="companyAddress"
              {...register("companyAddress", {
                required: true,
              })}
            />
            {errors.url && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Company Address
              </div>
            )}
          </div> */}
          {/*           //company id,userId,incometype,companyLogo,
          companyLogText,companyName,companyAddress,companyLocation */}
          {/*       scope: "",
  url: "",
  agency: "", */}
          <div className="mb-2">
            <label>Company Logo Text</label>
            <input
              type="text"
              name="companyLogText"
              {...register("companyLogText", {
                required: true,
              })}
            />
            {errors.agency && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Company Logo Text
              </div>
            )}
          </div>
          <div className="my-2">
            <label>Company Logo Image</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddCompany;
