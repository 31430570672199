import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import MainLayout from "./template/MainLayout";

import {
  Accounts,
  Adverts,
  Dashboard,
  Occupations,
  Reports,
  SalePages,
  Settings,
  Signin,
  AccountPartner,
  AccountDistribute,
  AccountAdvert,
  AccountLogo,
  AdvertGov,
  AdvertUnit,
  AdvertProvider,
  PictureForSalepage,
  VideoForSalepage,
  YoutubeForSalepage,
  Users,
  Income,
  Location,
  AppInfomation,
  AccountType,
  ChangePwd,
  MainOccupation,
  MinorOccupation,
  Package,
  MPackage,
  SPackage,
  Policy,
  Company,
  HeroOffice,
  CompanyOffice,
  DownloadPvOffice,
  PopularOffice,
  MenuOffice,
  FooterOffice,
  PopularOfficeHeader,
  PolicyTerm,
  GalleryOffice,
  CompanyBank,
  AccountIntroduce,
  Bank,
  CreateCompanyLogo,
  MemberAddLogo,
  Blog,
} from "./pages";

import {
  ViewPartner,
  AddPartner,
  EditPartner,
  BankAccount,
  ListPartner,
  PartnerSale,
} from "./components/accounts/partner";

import { ViewMemberLogo, AddMemberLogo } from "./components/memberlogo";

import {
  ViewLogo,
  AddLogo,
  EditAcLogo,
  BankLogoAccount,
} from "./components/accounts/logo";

import {
  ViewDistribute,
  AddDistribute,
  EditDistribute,
  BankDistributeAccount,
} from "./components/accounts/distribute";

import {
  ViewAccountAdvert,
  AddAcountAdvert,
  EditAccountAdvert,
  BankAdvertAccount,
} from "./components/accounts/advert";

import { AddGov, ViewGov, EditGov } from "./components/adverts/gov";

import { AddUnit, ViewUnit, EditUnit } from "./components/adverts/unit";

import {
  AddProvider,
  ViewProvider,
  EditProvider,
} from "./components/adverts/provider";

import { AddPic, ViewPic, EditPic } from "./components/salepages/pcsalepage";

import {
  AddVideo,
  ViewVideo,
  EditVideo,
} from "./components/salepages/visalepage";

import {
  AddSalepage,
  ViewSalepage,
  EditSalepage,
  PreviewSalepage,
} from "./components/salepages/salepage";

import {
  AddYoutube,
  ViewYoutube,
  EditYoutube,
} from "./components/salepages/yusalepage";

import { AddType, EditType, ViewType } from "./components/settings/account";

import {
  AddLocation,
  ViewLocation,
  EditLocation,
} from "./components/settings/location";

import { AddUser, ViewUser, EditUser } from "./components/settings/users";

import {
  AddIncome,
  EditIncome,
  ViewIncome,
} from "./components/settings/income";

import {
  AddAppInfo,
  ViewAppInfo,
  EditAppInfo,
} from "./components/settings/appinfo";

import {
  ViewAdvert,
  AddAdvert,
  EditAdvert,
} from "./components/settings/advert";

// import { AddUser, ViewUser, EditUser } from "./components/settings/users";

import {
  AddMainOcc,
  EditMainOcc,
  ViewMainOcc,
} from "./components/occupations/mainocc";

import {
  AddMinorOcc,
  EditMinorOcc,
  ViewMinorOcc,
} from "./components/occupations/minorocc";
//AddAppInfo, ViewAppInfo, EditAppInfo
import {
  AddMPackage,
  EditMPackage,
  ViewMPackage,
} from "./components/package/major";

import {
  AddSPackage,
  EditSPackage,
  ViewSPackage,
} from "./components/package/supplement";

import { AddCompany, EditCompany, ViewCompany } from "./components/company";
/* import {
  AddAppInfo,
  ViewAppInfo,
  EditAppInfo,
} from "./components/settings/appinfo"; */

import { AddHero, EditHero, ViewHero } from "./components/offices/heros";
import {
  AddPopular,
  EditPopular,
  ViewPopular,
} from "./components/offices/populars";
import {
  AddPopularHead,
  EditPopularHead,
  ViewPopularHead,
} from "./components/offices/popularheader";
import {
  AddDwPv,
  EditDwPv,
  ViewDwPv,
} from "./components/offices/downloadprovider";
import {
  AddOfCompany,
  EditOfCompany,
  ViewOfCompany,
} from "./components/offices/company";

import {
  ViewPT,
  AddPolicy,
  AddTerm,
  EditPolicy,
  EditTerm,
} from "./components/offices/policyterm";

import {
  AddCompanyBank,
  EditCompanyBank,
  ViewCompanyBank,
} from "./components/settings/companybank";

import {
  AddGallery,
  EditGallery,
  ViewGallery,
} from "./components/offices/gallery";

import { AddFaq, EditFaq, ViewFaq } from "./components/offices/faq";
import FaqOffice from "./pages/FaqOffice";

import {
  AddIntroduce,
  EditIntroduce,
  ViewIntroduce,
  BankIntroduceAccount,
} from "./components/accounts/introduce";

import { AddBank, EditBank, ViewBank } from "./components/settings/bank";

import {
  CreateLogo,
  EditLogoCompany,
  ViewLogoCompany,
} from "./components/settings/logo";
import {
  AddBlog,
  EditBlog,
  ViewBlog,
  AddBlogContent,
  ViewBlogContent,
  AddBlogGallery,
  ViewBlogGallery,
} from "./components/blog";
const Layout = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/companyinfo", //AddCompany, EditCompany, ViewCompany
        element: <Company />,
        children: [
          {
            path: "",
            element: <ViewCompany />,
          },
          {
            path: "addcompany",
            element: <AddCompany />,
          },
          {
            path: "editcompany/:id",
            element: <EditCompany />,
          },
        ],
      },
      {
        path: "/blog",
        element: <Blog />, //,AddBlogContent,ViewBlogContent,AddBlogGallery,ViewBlogGallery
        children: [
          {
            path: "",
            element: <ViewBlog />,
          },
          {
            path: "addblog",
            element: <AddBlog />,
          },
          {
            path: "editblog/:id",
            element: <EditBlog />,
          },
          {
            path: "addblogcontent/:id",
            element: <AddBlogContent />,
          },
          {
            path: "viewblogcontent/:id",
            element: <ViewBlogContent />,
          },
          {
            path: "addbloggallery/:id",
            element: <AddBlogGallery />,
          },
          {
            path: "viewbloggallery/:id",
            element: <ViewBlogGallery />,
          },
        ],
      },
      {
        path: "/acpartner",
        element: <AccountPartner />,
        children: [
          {
            path: "",
            element: <ViewPartner />,
          },
          {
            path: "addacpartner",
            element: <AddPartner />,
          },
          {
            path: "editacpartner/:id",
            element: <EditPartner />,
          },
          {
            path: "bankpartner/:id",
            element: <BankAccount />,
          },
          {
            path: "listpartner",
            element: <ListPartner />,
          },
          {
            path: "partnersale/:id",
            element: <PartnerSale />,
          },
        ],
      },
      {
        path: "/acmemberlogo",
        element: <MemberAddLogo />,
        children: [
          {
            path: "",
            element: <ViewMemberLogo />,
          },
          {
            path: "addmemberlogo/:id",
            element: <AddMemberLogo />,
          },
        ],
      },
      {
        path: "/acdistribute",
        element: <AccountDistribute />,
        children: [
          {
            path: "",
            element: <ViewDistribute />,
          },
          {
            path: "addacdistribute",
            element: <AddDistribute />,
          },
          {
            path: "editacdistribute/:id",
            element: <EditDistribute />,
          },
          {
            path: "bankacdistribute/:id",
            element: <BankDistributeAccount />,
          },
        ],
      },
      {
        path: "/acadvert", //addacdistribute
        element: <AccountAdvert />,
        children: [
          {
            path: "",
            element: <ViewAccountAdvert />,
          },
          {
            path: "addacadvert",
            element: <AddAcountAdvert />,
          },
          {
            path: "editacadvert/:id",
            element: <EditAccountAdvert />,
          },
          {
            path: "bankacadvert/:id",
            element: <BankAdvertAccount />,
          },
        ],
      },
      {
        path: "/acintroduce", //addacdistribute
        element: <AccountIntroduce />,
        children: [
          {
            path: "", //AddIntroduce, EditIntroduce, ViewIntroduce
            element: <ViewIntroduce />,
          },
          {
            path: "addacintroduce",
            element: <AddIntroduce />,
          },
          {
            path: "editacintroduce/:id",
            element: <EditIntroduce />,
          },
          {
            path: "bankacintroduce/:id",
            element: <BankIntroduceAccount />,
          },
        ],
      },
      {
        path: "/aclogo",
        element: <AccountLogo />,
        children: [
          {
            path: "",
            element: <ViewLogo />,
          },
          {
            path: "addaclogo",
            element: <AddLogo />,
          },
          {
            path: "editaclogo/:id",
            element: <EditAcLogo />,
          },
          {
            path: "bankaclogo/:id",
            element: <BankLogoAccount />,
          },
        ],
      },
      /*       {
        path: "/account",
        element: <Accounts />,
        children: [
          {
            path: "/viewpartner",
            element: <ViewPartner />,
          },
          {
            path: "/viewdistribute",
            element: <ViewDistribute />,
          },
          {
            path: "/viewaccountadvert",
            element: <ViewAccountAdvert />,
          },
          {
            path: "/viewlogo",
            element: <ViewLogo />,
          },
        ],
      }, */
      {
        path: "/advertgov",
        element: <AdvertGov />,
        children: [
          {
            path: "",
            element: <ViewGov />,
          },
          {
            path: "addgov",
            element: <AddGov />,
          },
          {
            path: "editgov/:id",
            element: <EditGov />,
          },
        ],
      },
      {
        path: "/advertunit",
        element: <AdvertUnit />,
        children: [
          {
            path: "",
            element: <ViewUnit />,
          },
          {
            path: "addunit",
            element: <AddUnit />,
          },
          {
            path: "editunit/:id",
            element: <EditUnit />,
          },
        ],
      },
      {
        path: "/advertprovider",
        element: <AdvertProvider />,
        children: [
          {
            path: "",
            element: <ViewProvider />,
          },
          {
            path: "addprovider",
            element: <AddProvider />,
          },
          {
            path: "editprovider/:id",
            element: <EditProvider />,
          },
        ],
      },
      {
        path: "/occupation",
        element: <Occupations />,
      },
      {
        path: "/mainoccupation", //  AddMainOcc, EditMainOcc, ViewMainOcc,
        element: <MainOccupation />,
        children: [
          {
            path: "",
            element: <ViewMainOcc />,
          },
          {
            path: "addmainocc",
            element: <AddMainOcc />,
          },
          {
            path: "editmainocc/:id",
            element: <EditMainOcc />,
          },
        ],
      },
      {
        path: "/minoroccupation", //AddMinorOcc, EditMinorOcc, ViewMinorOcc
        element: <MinorOccupation />,
        children: [
          {
            path: "",
            element: <ViewMinorOcc />,
          },
          {
            path: "addminorocc",
            element: <AddMinorOcc />,
          },
          {
            path: "editminorocc/:id",
            element: <EditMinorOcc />,
          },
        ],
      },
      {
        path: "/salepage",
        element: <SalePages />,
        children: [
          {
            path: "",
            element: <ViewSalepage />,
          },
          {
            path: "addsalepage",
            element: <AddSalepage />,
          },
          {
            path: "editsalepage/:id",
            element: <EditSalepage />,
          },
          {
            path: "previewsalepage/:id",
            element: <PreviewSalepage />,
          },
        ],
      },
      {
        path: "/pcsalepage",
        element: <PictureForSalepage />,
        children: [
          {
            path: "",
            element: <ViewPic />,
          },
          {
            path: "addpic",
            element: <AddPic />,
          },
          {
            path: "editpic/:id",
            element: <EditPic />,
          },
        ],
      },
      {
        path: "/visalepage",
        element: <VideoForSalepage />,
        children: [
          {
            path: "",
            element: <ViewVideo />,
          },
          {
            path: "addvideo",
            element: <AddVideo />,
          },
          {
            path: "editvideo/:id",
            element: <EditVideo />,
          },
        ],
      },
      {
        path: "/yusalepage",
        element: <YoutubeForSalepage />,
        children: [
          {
            path: "",
            element: <ViewYoutube />,
          },
          {
            path: "addyutube",
            element: <AddYoutube />,
          },
          {
            path: "edityutube/:id",
            element: <EditYoutube />,
          },
        ],
      },
      {
        path: "/occupation",
        element: <Occupations />,
      },
      {
        path: "/createlogos",
        element: <CreateCompanyLogo />, //CreateLogo, EditLogoCompany, ViewLogoCompany
        children: [
          {
            path: "", //AddUser, ViewUser, EditUser
            element: <ViewLogoCompany />,
          },
          {
            path: "addcomlogo",
            element: <CreateLogo />,
          },
          {
            path: "editcomlogo/:id",
            element: <EditLogoCompany />,
          },
        ],
      },
      {
        path: "/report",
        element: <Reports />,
      },
      {
        path: "/mpackages", //AddMPackage, EditMPackage, ViewMPackage
        element: <MPackage />,
        children: [
          {
            path: "", //AddUser, ViewUser, EditUser
            element: <ViewMPackage />,
          },
          {
            path: "addmpk",
            element: <AddMPackage />,
          },
          {
            path: "editmpk/:id",
            element: <EditMPackage />,
          },
        ],
      },
      {
        path: "/spackages", //AddSPackage, EditSPackage, ViewSPackage
        element: <SPackage />,
        children: [
          {
            path: "", //AddUser, ViewUser, EditUser
            element: <ViewSPackage />,
          },
          {
            path: "addspk",
            element: <AddSPackage />,
          },
          {
            path: "editspk/:id",
            element: <EditSPackage />,
          },
        ],
      },
      {
        path: "/packages",
        element: <Package />,
      },
      {
        path: "/users",
        element: <Users />,
        children: [
          {
            path: "", //AddUser, ViewUser, EditUser
            element: <ViewUser />,
          },
          {
            path: "addgfuser",
            element: <AddUser />,
          },
          {
            path: "editgfuser/:id",
            element: <EditUser />,
          },
        ],
      },
      {
        path: "/income", //AddIncome, EditIncome, ViewIncome
        element: <Income />,
        children: [
          {
            path: "", //AddUser, ViewUser, EditUser
            element: <ViewIncome />,
          },
          {
            path: "addgfincome",
            element: <AddIncome />,
          },
          {
            path: "editgfincome/:id",
            element: <EditIncome />,
          },
        ],
      },
      {
        path: "/location", //AddLocation, ViewLocation, EditLocation
        element: <Location />,
        children: [
          {
            path: "", //AddType, EditType, ViewType
            element: <ViewLocation />,
          },
          {
            path: "addlocation",
            element: <AddLocation />,
          },
          {
            path: "editlocation/:id",
            element: <EditLocation />,
          },
        ],
      },
      {
        path: "/appinfo", //AddAppInfo, ViewAppInfo, EditAppInfo
        element: <AppInfomation />,
        children: [
          {
            path: "", //AddType, EditType, ViewType
            element: <ViewAppInfo />,
          },
          {
            path: "addappinfo",
            element: <AddAppInfo />,
          },
          {
            path: "editappinfo/:id",
            element: <EditAppInfo />,
          },
        ],
      },
      {
        path: "/acctype",
        element: <AccountType />,
        children: [
          {
            path: "", //AddType, EditType, ViewType
            element: <ViewType />,
          },
          {
            path: "addaccounttype",
            element: <AddType />,
          },
          {
            path: "editaccounttype/:id",
            element: <EditType />,
          },
        ],
      },
      {
        path: "/bank",
        element: <Bank />,
        children: [
          {
            path: "", //AddBank, EditBank, ViewBank
            element: <ViewBank />,
          },
          {
            path: "addbank",
            element: <AddBank />,
          },
          {
            path: "editbank/:id",
            element: <EditBank />,
          },
        ],
      },
      {
        path: "/companybank",
        element: <CompanyBank />,
        children: [
          {
            path: "", //AddCompanyBank, EditCompanyBank, ViewCompanyBank
            element: <ViewCompanyBank />,
          },
          {
            path: "addcompanybank",
            element: <AddCompanyBank />,
          },
          {
            path: "editcompanybank/:id",
            element: <EditCompanyBank />,
          },
        ],
      },
      {
        path: "/herooffice", //  HeroOffice,CompanyOffice,DownloadPvOffice,PopularOffice,MenuOffice,FooterOffice
        element: <HeroOffice />,
        children: [
          {
            path: "", //AddHero, EditHero, ViewHero
            element: <ViewHero />,
          },
          {
            path: "addhero",
            element: <AddHero />,
          },
          {
            path: "edithero/:id",
            element: <EditHero />,
          },
        ],
      },
      {
        path: "/companyoffice", //  HeroOffice,CompanyOffice,DownloadPvOffice,PopularOffice,MenuOffice,FooterOffice
        element: <CompanyOffice />,
        children: [
          {
            path: "", //AddOfCompany, EditOfCompany, ViewOfCompany
            element: <ViewOfCompany />,
          },
          {
            path: "addofcompany",
            element: <AddOfCompany />,
          },
          {
            path: "editofcompany/:id",
            element: <EditOfCompany />,
          },
        ],
      },
      {
        path: "/downloadpvoffice", //  HeroOffice,CompanyOffice,DownloadPvOffice,PopularOffice,MenuOffice,FooterOffice
        element: <DownloadPvOffice />,
        children: [
          {
            path: "", //AddDwPv, EditDwPv, ViewDwPv
            element: <ViewDwPv />,
          },
          {
            path: "addofdwpv",
            element: <AddDwPv />,
          },
          {
            path: "editofdwpv/:id",
            element: <EditDwPv />,
          },
        ],
      },
      {
        path: "/populatofhead", //  HeroOffice,CompanyOffice,DownloadPvOffice,PopularOffice,MenuOffice,FooterOffice
        element: <PopularOfficeHeader />,
        children: [
          {
            path: "", //AddPopularHead, EditPopularHead, ViewPopularHead
            element: <ViewPopularHead />,
          },
          {
            path: "addofpophead",
            element: <AddPopularHead />,
          },
          {
            path: "editofpophead/:id",
            element: <EditPopularHead />,
          },
        ],
      },
      {
        path: "/populatoffice", //  HeroOffice,CompanyOffice,DownloadPvOffice,PopularOffice,MenuOffice,FooterOffice
        element: <PopularOffice />,
        children: [
          {
            path: "", //AddPopular, EditPopular, ViewPopular
            element: <ViewPopular />,
          },
          {
            path: "addofpopular",
            element: <AddPopular />,
          },
          {
            path: "editofpopular/:id",
            element: <EditPopular />,
          },
        ],
      },
      {
        path: "/galleryoffice", //  HeroOffice,CompanyOffice,DownloadPvOffice,PopularOffice,MenuOffice,FooterOffice
        element: <GalleryOffice />,
        children: [
          {
            path: "", // AddGallery, EditGallery, ViewGallery
            element: <ViewGallery />,
          },
          {
            path: "addgallery",
            element: <AddGallery />,
          },
          {
            path: "editgallery/:id",
            element: <EditGallery />,
          },
        ],
      },
      {
        path: "/faqoffice", //  HeroOffice,CompanyOffice,DownloadPvOffice,PopularOffice,MenuOffice,FooterOffice
        element: <FaqOffice />,
        children: [
          {
            path: "", //AddFaq, EditFaq, ViewFaq
            element: <ViewFaq />,
          },
          {
            path: "addfaq",
            element: <AddFaq />,
          },
          {
            path: "editfaq/:id",
            element: <EditFaq />,
          },
        ],
      },
      {
        path: "/policyterm", //  HeroOffice,CompanyOffice,DownloadPvOffice,PopularOffice,MenuOffice,FooterOffice
        element: <PolicyTerm />,
        children: [
          {
            path: "", //ViewPT, AddPolicy, AddTerm, EditPolicy, EditTerm
            element: <ViewPT />,
          },
          {
            path: "addpolicy",
            element: <AddPolicy />,
          },
          {
            path: "addterm",
            element: <AddTerm />,
          },
          {
            path: "editpolicy/:id",
            element: <EditPolicy />,
          },
          {
            path: "editterm/:id",
            element: <EditTerm />,
          },
        ],
      },
      /*       {
        path: "/acctype",
        element: <AccountType />,
      }, */
      {
        path: "/changepwd",
        element: <ChangePwd />,
      },
      {
        path: "/settings",
        element: <Settings />,
        /*         children: [
          {
            path: "",
            element: <MainSettings />,
            children: [
              {
                path: "",
                element: <ViewType />,
                children: [
                  {
                    path: "addtype",
                    element: <AddType />,
                  },
                  {
                    path: "edittype",
                    element: <EditType />,
                  },
                ],
              },
            ],
          }, */
        /*           {
            path: "addtype",
            element: <AddType />,
          },
          {
            path: "edittype",
            element: <EditType />,
          }, */
        // ],
      },
    ],
  },
  {
    path: "login",
    element: <Signin />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
