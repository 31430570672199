import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  pnSalepageUrl,
  pnSalepageFileUrl,
  gfSalepageUrl,
  gfSalepageFileUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

import { FaRegEye } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  BiCopyAlt,
} from "react-icons/bi";
// import { IconName } from "react-icons/fc";
// FaRegEye
//BiCopyAlt
const ViewSalepage = () => {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [userId, setUserId] = useState("");
  //gfSalepageUrl,gfSalepageFileUrl
  function readData(id) {
    axios
      .get(`${gfSalepageUrl}getsalepage.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
      });
  }

  useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
    readData(userId);
  }, [userId, datas]);

  /*   useCallback(() => {
    readData(userId);
    return () => {};
  }, []); */

  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${gfSalepageUrl}delete.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readData(userId);
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };
  return (
    <div>
      {/* <h2>{userId}</h2> */}
      {/* locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at */}
      {datas.map((item, index) => (
        <div key={index} className="">
          <Card>
            <div className="flex flex-col gap-2 md:flex-row">
              <div className="flex flex-col w-full md:flex-row flex-wrap">
                {item.picture != "" ? (
                  <img
                    src={`${gfSalepageFileUrl}${item.picture}`}
                    className="rounded-lg m-2 w-[300px]"
                  />
                ) : (
                  ""
                )}
                {item.picture2 != "" ? (
                  <img
                    src={`${gfSalepageFileUrl}${item.picture2}`}
                    className="rounded-lg m-2 w-[300px]"
                  />
                ) : (
                  ""
                )}
                {item.picture3 != "" ? (
                  <img
                    src={`${gfSalepageFileUrl}${item.picture3}`}
                    className="rounded-lg m-2 w-[300px]"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="mt-2 flex justify-end items-end">
                <CopyToClipboard
                  text={item?.salepageurl}
                  onCopy={() => toast.success("Successfully created!")}
                >
                  <button className="mx-4 bg-gray-500 hover:bg-gray-700 text-white text-center py-1 px-2 rounded">
                    <BiCopyAlt size={22} />
                  </button>
                </CopyToClipboard>

                <button
                  // onClick={() => confirmDelete(item.id)}
                  onClick={() => deleteData(item.id)}
                  className="mr-4 bg-red-500 hover:bg-red-700 text-white text-center py-1 px-2 rounded"
                >
                  <IoTrashOutline size={22} />
                </button>
                <Link
                  to={`editsalepage/${item.id}`}
                  className="mr-4 bg-purple-500 hover:bg-purple-700 text-white text-center py-1 px-2 rounded"
                >
                  <HiMiniPencilSquare size={22} />
                </Link>
                <Link
                  to={`previewsalepage/${item.id}`}
                  className="mr-4 bg-blue-500 hover:bg-blue-700 text-white text-center py-1 px-2 rounded"
                >
                  <FaRegEye size={22} />
                </Link>
              </div>
            </div>
            <Toaster />
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ViewSalepage;
