import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  yuSalepageUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css

// import ReactPlayer from "react-player";
import ReactPlayer from "react-player/lazy";

const EditYoutube = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [thName, setThName] = useState("");
  const [url, setUrl] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  //accounttype id,typename,description,flag,created_at
  function readData(id) {
    axios.get(`${yuSalepageUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setThName(response.data?.thName);
      setUrl(response.data?.url);
      setIsChecked(response.data?.isDisplay == "1" ? true : false);
    });
  }

  useEffect(() => {
    readData(id);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${yuSalepageUrl}update.php`, {
        idEdit: id,
        thName,
        url,
        isDisplay: isChecked ? "1" : "0",
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div className="w-full">
          {datas?.url ? (
            <div className="md:h-[300px]">
              <ReactPlayer
                controls={true}
                url={`${datas?.url}`}
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            "-"
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label>Discription</label>
            <input
              type="text"
              value={thName}
              onChange={(e) => setThName(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Link Youtube</label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>

          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              id="some_id"
              value="1"
              checked={isChecked}
              onChange={handleOnChange}
              className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
            />
            <label htmlFor="some_id"> Display in SalePage</label>
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditYoutube;
