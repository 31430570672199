import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
  officesHerosUrl,
  officesCompanyUrl,
  imgOfficeUrl,
  statusUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const EditOfCompany = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [flag, setFlag] = useState("");
  const [pagetypes, setPageTypes] = useState([]);
  const [imgPos, setImgPos] = useState(null);
  const [imgPosition, setImgPosition] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readPagetype() {
    axios.get(`${officesCompanyUrl}readpagetype.php`).then(function (response) {
      console.log(response.data);
      setPageTypes(response.data);
    });
  }
  function readImagePosition() {
    axios.get(`${statusUrl}readimgpos.php`).then(function (response) {
      console.log(response.data);
      setImgPosition(response.data);
    });
  }
  function readData(id) {
    axios
      .get(`${officesCompanyUrl}readbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setTitle(response.data?.title);
        setSubtitle(response.data?.subtitle);
        setDescription(response.data?.description);
        setFlag(response.data?.flag);
        setImgPos(response.data?.imageposition);
        // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
      });
  }

  useEffect(() => {
    readData(id);
    readPagetype();
    readImagePosition();
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("title", title);
    formData.append("subtitle", subtitle);
    formData.append("description", description);
    formData.append("flag", flag);
    formData.append("imageposition", imgPos);
    formData.append("idEdit", id);
    setLoading(true);

    axios
      .post(`${officesCompanyUrl}update.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.photo !== null ? (
            <img
              src={`${imgOfficeUrl}${datas?.photo}`}
              className="rounded-lg mb-4 h-[300px]"
            />
          ) : null}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Page Name</label>
            <select
              value={flag}
              onChange={(e) => setFlag(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {pagetypes.map((item, index) => (
                <option key={index} value={item.flag}>
                  {item.pagename}/{item.flag}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Subtitle</label>
            <input
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Discription</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Image Position</label>
            <select
              value={imgPos}
              onChange={(e) => setImgPos(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {imgPosition.map((item, index) => (
                <option key={index} value={item.imgposition}>
                  {item.title}( {item.imgposition})
                </option>
              ))}
            </select>
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditOfCompany;
