import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  introduceUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const ShowPartner = ({ introduceId }) => {
  //introduceUrl
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  function readData(id) {
    axios
      .get(`${introduceUrl}readincomepartner.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
      });
  }

  useEffect(() => {
    readData(introduceId);
  }, []);
  return (
    <div>
      <h2 className="ml-4 mt-4 text-lg font-extrabold">Partner</h2>
      {datas.map((item, index) => (
        <Card key={index}>
          <div className="w-full md:w-1/2">
            <h2 className="font-semibold">
              <strong>Full name:</strong> {item?.firstname} {item?.lastname}
            </h2>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ShowPartner;
