import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  advertUrl,
  advertTypeUrl,
  govUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const EditAccountAdvert = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [firstnameEdit, setFirstnameEdit] = useState("");
  const [lastnameEdit, setLastnameEdit] = useState("");
  const [phoneEdit, setPhoneEdit] = useState("");
  const [emailEdit, setEmailEdit] = useState("");
  const [passwordEdit, setPasswordEdit] = useState("");
  const [pltype, setPltype] = useState("");
  const [scope, setScope] = useState("");
  const [partnerData, setPartnerData] = useState({});
  const [dataType, setDataType] = useState([]);
  const [locations, setLocations] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readLocation() {
    axios.get(`${govUrl}location.php`).then(function (response) {
      console.log(response.data);
      setLocations(response.data);
    });
  }
  function readTypeData() {
    axios.get(`${advertTypeUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDataType(response.data);
    });
  }

  /*   function readPartner(id) {
    axios.get(`${partnerUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setPartnerData(response.data);
    });
  }

  useEffect(() => {
    readPartner(id);
    readTypeData();
  }, []); */
  //accounttype id,typename,description,flag,created_at
  function readData(id) {
    axios.get(`${advertUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setFirstnameEdit(response.data?.firstname);
      setLastnameEdit(response.data?.lastname);
      setPhoneEdit(response.data?.phone);
      setEmailEdit(response.data?.email);
      setPasswordEdit(response.data?.password);
      setPltype(response.data?.avtype);
      setScope(response.data?.scope);
    });
  }

  useEffect(() => {
    readData(id);
    readTypeData();
    readLocation();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${advertUrl}update.php`, {
        idEdit: id,
        firstnameEdit,
        lastnameEdit,
        emailEdit,
        phoneEdit,
        passwordEdit,
        pltype,
        scope,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label>Scope</label>
            <select
              value={scope}
              onChange={(e) => setScope(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              <option value="0">มองเห็นทั้งหมด</option>
              {locations.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.thName}
                  {item.enName ? `(${item.enName})` : ""}-{item.id}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-6">
              <label>First Name:</label>
              <input
                type="text"
                value={firstnameEdit}
                onChange={(e) => setFirstnameEdit(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label>Last Name:</label>
              <input
                type="text"
                value={lastnameEdit}
                onChange={(e) => setLastnameEdit(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-6">
            <label>Phone:</label>
            <input
              type="tel"
              value={phoneEdit}
              onChange={(e) => setPhoneEdit(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label>E-mail:</label>
            <input
              type="email"
              value={emailEdit}
              onChange={(e) => setEmailEdit(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label>Password:</label>
            <input
              type="password"
              value={passwordEdit}
              onChange={(e) => setPasswordEdit(e.target.value)}
            />
          </div>

          <div className="mb-2">
            <label>Account Type</label>
            <select
              value={pltype}
              onChange={(e) => setPltype(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {dataType.map((item, index) => (
                <option key={index} value={item.flag}>
                  {item.typename}( {item.flag})
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditAccountAdvert;
