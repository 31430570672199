import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { settingsTypeUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddType = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      typename: "",
      description: "",
      flag: "",
    },
  });
  //accounttype id,typename,description,flag,created_at
  //C:\xampp\htdocs\appApl\settings\accounttype
  // console.log(`${settingsTypeUrl}addactype.php`);
  const [loading, setLoading] = useState(false);
  const handleOnSubmit = (data) => {
    setLoading(true);

    axios
      .post(`${settingsTypeUrl}addactype.php`, {
        typename: data.typename,
        description: data.description,
        flag: data.flag,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        // toast.success("Successfully created!");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };

  /*   const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }); */
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Type Name</label>
            <input
              type="text"
              // placeholder="ชื่อจริง"
              name="typename"
              {...register("typename", {
                required: true,
              })}
            />
            {errors.typename && <div className="">Type Name</div>}
          </div>
          <div className="mb-2">
            <label>Description</label>
            <input
              type="text"
              // placeholder="นามสกุลจริง"
              name="description"
              {...register("description", {
                required: true,
              })}
            />
            {errors.description && (
              <div className="my-3 text-sm italic text-red-500">
                Enter Description
              </div>
            )}
          </div>

          <div className="mb-2">
            <label>Flag</label>
            <input
              type="text"
              // placeholder="Country Code"
              {...register("flag", {
                required: true,
              })}
            />
            {errors.flag && (
              <div className="my-3 text-sm italic text-red-500">Flag</div>
            )}
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        {/* <Toaster /> */}
      </Card>
    </div>
  );
};

export default AddType;
