import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
  pltype,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const AddUser = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState({});
  const [isEmail, setIsEmail] = useState(false);
  const handleOnSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${gfUserUrl}checkemail.php`, {
        // pid: userId,
        email: data.email,
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === 1) {
          setIsEmail(true);
          setUser(response.data);
        } else {
          axios
            .post(`${gfUserUrl}add.php`, {
              firstname: data.firstname,
              lastname: data.lastname,
              phone: data.phone,
              email: data.email,
              password: data.password,
              pltype: pltype,
            })
            .then(function (response) {
              console.log(response.data);
              setIsEmail(false);
              reset();
              // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              setLoading(false); // Stop loading
            });
          //-------------else
        }
      });

    //-----------
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-2">
              <label>FirstName:</label>
              <input
                type="text"
                name="firstname"
                {...register("firstname", {
                  required: true,
                })}
              />
              {errors.firstname && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter FirstName
                </div>
              )}
            </div>
            <div className="mb-2">
              <label>LastName:</label>
              <input
                type="text"
                name="lastname"
                {...register("lastname", {
                  required: true,
                })}
              />
              {errors.lastname && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter LastName
                </div>
              )}
            </div>
          </div>
          <div className="mb-2">
            <label>Phone:</label>
            <input
              type="tel"
              {...register("phone", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.phone && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Phone
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>E-mail:</label>
            <input
              type="email"
              {...register("email", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.email && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter E-mail
              </div>
            )}
            {isEmail && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                User name is already used or if an existing email address that's
                based on the user name already exists
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Password</label>
            <input
              type="password"
              {...register("password", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.password && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Password
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddUser;
