import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  officesHerosUrl,
  officesCompanyUrl,
  imgOfficeUrl,
  statusUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddOfCompany = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      subtitle: "",
      description: "",
      flag: "",
      imgpos: "",
    },
  });
  //  // title,subtitle,discription,titledownload,androiduser,iosuser
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [pagetypes, setPageTypes] = useState([]);
  const [imgPosition, setImgPosition] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readPagetype() {
    axios.get(`${officesCompanyUrl}readpagetype.php`).then(function (response) {
      console.log(response.data);
      setPageTypes(response.data);
    });
  }
  function readImagePosition() {
    axios.get(`${statusUrl}readimgpos.php`).then(function (response) {
      console.log(response.data);
      setImgPosition(response.data);
    });
  }
  useEffect(() => {
    readPagetype();
    readImagePosition();
  }, []);
  //imageposition
  // office_company id,title,subtitle,icon,description,photo,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("title", data.title);
    formData.append("subtitle", data.subtitle);
    formData.append("description", data.description);
    formData.append("imageposition", data.imgpos);
    formData.append("flag", data.flag);
    setLoading(true);
    // office_company id,title,subtitle,icon,description,photo,created_at
    axios
      .post(`${officesCompanyUrl}add.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Page Name</label>
            <select
              {...register("flag", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {pagetypes.map((item, index) => (
                <option key={index} value={item.flag}>
                  {item.pagename}/{item.flag}
                </option>
              ))}
            </select>
            {errors.flag && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Page Name
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              name="title"
              {...register("title", {
                required: true,
              })}
            />
            {errors.title && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Title
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Subtitle</label>
            <input
              type="text"
              name="subtitle"
              {...register("subtitle", {
                required: true,
              })}
            />
            {errors.subtitle && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Subtitle
              </div>
            )}
          </div>
          {/*  title,subtitle,discription,titledownload,androiduser,iosuser */}
          <div className="mb-2">
            <label>description</label>
            <input
              type="text"
              name="description"
              {...register("description", {
                required: true,
              })}
            />
            {errors.description && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Description
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Image Position</label>
            <select
              {...register("imgpos", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> imageposition id,title,imgposition,created_at*/}
              {imgPosition.map((item, index) => (
                <option key={index} value={item.imgposition}>
                  {item.title}( {item.imgposition})
                </option>
              ))}
            </select>
            {errors.imgpos && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Select Image Position
              </div>
            )}
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddOfCompany;
