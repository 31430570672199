import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams, Outlet } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { WelcomeRow } from "../components";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
} from "../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import { Card } from "../components";

import Modal from "react-modal";

import Swal from "sweetalert2";

const ChangePwd = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "รหัสผ่านต้อง 6 ตัวขึ้นไป")
      .required("ป้อนรหัสผ่าน"),
    confirmPassword: Yup.string()
      .min(6, "ยืนยันรหัสผ่านต้อง 6 ตัวขึ้นไป")
      .oneOf([Yup.ref("password")], "รหัสผ่านไม่ตรงกัน")
      .required("ป้อนยืนยันรหัสผ่าน"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  /*   useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
  }, []); */
  useEffect(() => {
    var uID = localStorage.getItem("id");
    setUserId(uID);
  }, []);

  function onSubmit(data) {
    setLoading(true);
    // display form data on success
    // alert("เปลี่ยนรหัสผ่านสำเร็จ :-)\n\n" + JSON.stringify(data, null, 4));
    axios
      .post(`${gfUserUrl}changepwd.php`, {
        pid: userId,
        password: data.password,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
    return false;
  }
  return (
    <>
      <WelcomeRow />
      <div className="w-full lg:w-1/2 lg:ml-auto">
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label>รหัสผ่านใหม่</label>
              <input type="password" {...register("password")} />
              <div className="my-3 text-normal text-red-500 text-xs italic">
                {errors.password?.message}
              </div>
            </div>
            <div className="mb-2">
              <label>ยืนยันรหัสผ่านใหม่</label>
              <input type="password" {...register("confirmPassword")} />
              <div className="my-3 text-normal text-red-500 text-xs italic">
                {errors.confirmPassword?.message}
              </div>
            </div>

            <div className="flex justify-end">
              <button disabled={loading} type="submit" className="btnblue">
                {loading ? <>Please wait..</> : <>Save</>}
              </button>
            </div>
          </form>
        </Card>
      </div>
      {/*       <div className="mb-6 items-center">
        <div className="flex items-center justify-end gap-1  rounded-md overflow-hidden">
          <Link to={""} className="btntrans">
            แสดงข้อมูล 123
          </Link>
          <Link to={"addacadvert"} className="btnblue">
            เพิ่มข้อมูล 456
          </Link>
        </div>
      </div>
      <div>
        <Outlet />
      </div> */}
    </>
  );
};

export default ChangePwd;
