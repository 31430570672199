import React from "react";
import { Navbar, Menu, Footer, Navigation } from "../components";

const MainLayout = ({ children }) => {
  return (
    <div className="flex min-h-screen">
      {/* <Navbar /> */}
      <Navigation />
      <main className="p-5 w-full grow bg-white my-2 mr-2 rounded-3xl overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100">
        {children}
      </main>
      {/*       <div className="flex">
        <div className="w-[250px] py-[5px] px-[20px] border-r-2">
          <Menu />
        </div>
        <div>{children}</div>
      </div> */}
      {/* <Footer /> */}
    </div>
  );
};

export default MainLayout;
