import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
// import { menu } from "../utils/data";
/* import { IconName } from "react-icons/fa";
import { IconName } from "react-icons/bi";
import { IconName } from "react-icons/hi"; */
//
import {
  IoHomeOutline,
  IoImageOutline,
  IoVideocamOutline,
} from "react-icons/io5";
import { BiSolidPackage } from "react-icons/bi"; //FiPackage,BiSolidPackage
import { FiPackage } from "react-icons/fi";
//HiOutlineMegaphone
import { HiOutlineMegaphone } from "react-icons/hi2";
import { GrYoutube } from "react-icons/gr";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineShop } from "react-icons/ai";
// import { IconName } from "react-icons/ai";
import {
  MdAddchart,
  MdOutlineAccountBox,
  MdOutlineAccountCircle,
  MdAssignmentAdd,
  MdPhoneAndroid,
  MdCoPresent,
} from "react-icons/md";

import { BsGlobeAmericas, BsPersonLock, BsPersonGear } from "react-icons/bs";
// import { VscPreview } from "react-icons/vsc";
// import { IconName } from "react-icons/hi2";

import axios from "axios";
import { gfcompanyUrl, imgLogoUrl } from "../utils/baseUrl";
import MyTooltip from "./MyTooltip";

const Navigation = () => {
  const location = useLocation();
  console.log("pathname", location.pathname);
  //   const {pathname} = router;
  const pathname = location.pathname;
  //pathname /acintroduce/addacintroduce
  const activeClasses =
    "bg-white flex items-center text-primary gap-2 px-2 md:px-4 py-1 pr-2 md:pr-7 mb-2 rounded-xl rounded-r-none justify-center md:justify-start";
  const inactiveClasses =
    "block px-2 md:px-4 pr-2 md:pr-7 py-1 mb-2 flex items-center gap-2 justify-center md:justify-start";
  const links = [
    {
      id: 1,
      title: "dashboard",
      listItems: [
        {
          id: 1,
          title: "Homepage",
          url: "/",
          icon: <IoHomeOutline size={22} />,
        },
        {
          id: 2,
          title: "MyCompany",
          url: "/companyinfo",
          icon: <AiOutlineShop size={22} />,
        },
      ],
    },
    {
      id: 2,
      title: "Accounts",
      listItems: [
        {
          id: 1,
          title: "Partner",
          url: "/acpartner",
          icon: <MdOutlineAccountBox size={22} />,
        },
        /*         {
          id: 2,
          title: "Distribute",
          url: "acdistribute",
          icon: <MdOutlineAccountCircle size={22} />,
        }, */
        {
          id: 2,
          title: "Advert",
          url: "/acadvert",
          icon: <MdOutlineAccountBox size={22} />,
        },
        {
          id: 3,
          title: "Logo",
          url: "/aclogo",
          icon: <MdOutlineAccountCircle size={22} />,
        },
        {
          id: 4,
          title: "Introduce",
          url: "/acintroduce",
          icon: <MdOutlineAccountCircle size={22} />,
        },
        {
          id: 5,
          title: "MemberLogo*****",
          url: "/acmemberlogo",
          icon: <MdOutlineAccountCircle size={22} />,
        },
      ],
    },
    {
      id: 3,
      title: "Adverts",
      listItems: [
        {
          id: 1,
          title: "(Top)Gov",
          url: "/advertgov",
          icon: <HiOutlineMegaphone size={22} />,
        },
        {
          id: 2,
          title: "(Middle)Unit",
          url: "/advertunit",
          icon: <HiOutlineMegaphone size={22} />,
        },
        {
          id: 3,
          title: "(Bottom)Provider",
          url: "/advertprovider",
          icon: <HiOutlineMegaphone size={22} />,
        },
      ],
    },
    {
      id: 4,
      title: "SalePages",
      listItems: [
        {
          id: 1,
          title: "Create/View",
          url: "/salepage",
          icon: <MdAddchart size={22} />,
        },
        {
          id: 2,
          title: "Picture",
          url: "/pcsalepage",
          icon: <IoImageOutline size={22} />,
        },
        {
          id: 3,
          title: "Video",
          url: "/visalepage",
          icon: <IoVideocamOutline size={22} />,
        },
        {
          id: 4,
          title: "Youtube",
          url: "/yusalepage",
          icon: <GrYoutube size={22} />,
        },
      ],
    },
    {
      id: 5,
      title: "Occupation",
      listItems: [
        {
          id: 1,
          title: "Main",
          url: "/mainoccupation",
          icon: <MdCoPresent size={22} />,
        },
        {
          id: 2,
          title: "Minor",
          url: "/minoroccupation",
          icon: <MdCoPresent size={22} />,
        },
      ],
    },
    {
      id: 6,
      title: "Packages",
      listItems: [
        {
          id: 1,
          title: "Packages",
          url: "/mpackages",
          icon: <BiSolidPackage size={22} />,
        },
        /*         {
          id: 2,
          title: "Supplement",
          url: "/spackages",
          icon: <FiPackage size={22} />,
        }, */
      ],
    },
    {
      id: 7,
      title: "Offices", //Occupations DownloadPvOffice
      listItems: [
        {
          id: 1,
          title: "Hero Section",
          url: "/herooffice",
          icon: <BsPersonGear size={22} />,
        },

        {
          id: 2,
          title: "Company Section",
          url: "/companyoffice",
          icon: <MdPhoneAndroid size={22} />,
        },
        {
          id: 3,
          title: "Provider App",
          url: "/downloadpvoffice",
          icon: <FaRegUser size={22} />,
        },
        {
          id: 4,
          title: "Features Header",
          url: "/populatofhead",
          icon: <BsPersonLock size={22} />,
        },
        {
          id: 5,
          title: "Features Content",
          url: "/populatoffice",
          icon: <BsGlobeAmericas size={22} />,
        },
        {
          id: 6,
          title: "Gallery",
          url: "/galleryoffice",
          icon: <BsGlobeAmericas size={22} />,
        },
        {
          id: 7,
          title: "FAQ",
          url: "/faqoffice",
          icon: <MdAssignmentAdd size={22} />,
        },
        {
          id: 8,
          title: "Policy and Term",
          url: "/policyterm",
          icon: <MdAssignmentAdd size={22} />,
        },
        {
          id: 9,
          title: "Blogs",
          url: "/blog",
          icon: <MdAssignmentAdd size={22} />,
        },
      ],
    },
    {
      id: 8,
      title: "Settings", //Occupations
      listItems: [
        {
          id: 1,
          title: "Income Type",
          url: "/acctype",
          icon: <BsPersonGear size={22} />,
        },
        {
          id: 2,
          title: "Provice",
          url: "/location",
          icon: <BsGlobeAmericas size={22} />,
        },
        {
          id: 3,
          title: "Company Bank",
          url: "/companybank",
          icon: <MdPhoneAndroid size={22} />,
        },
        {
          id: 4,
          title: "App",
          url: "/appinfo",
          icon: <MdPhoneAndroid size={22} />,
        },
        {
          id: 5,
          title: "Users",
          url: "/users",
          icon: <FaRegUser size={22} />,
        },
        {
          id: 6,
          title: "Change Password",
          url: "/changepwd",
          icon: <BsPersonLock size={22} />,
        },
        {
          id: 7,
          title: "Incomes",
          url: "/income",
          icon: <MdAssignmentAdd size={22} />,
        },
        {
          id: 8,
          title: "Banks",
          url: "/bank",
          icon: <MdAssignmentAdd size={22} />,
        },
        {
          id: 9,
          title: "Create Logo",
          url: "/createlogos",
          icon: <MdAssignmentAdd size={22} />,
        },
      ],
    },
  ];

  const [dataApp, setDataApp] = useState({});

  function readAppData() {
    axios.get(`${gfcompanyUrl}readlogo.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);

  return (
    <aside className="bg-primary md:w-[400px] lg:w-[350px] text-white p-1 md:p-5 py-12 pr-0 md:pr-0 overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100">
      <NavLink
        to={"/"}
        className="hidden md:flex mb-6 mt-4 text-center items-center justify-center mr-2 gap-2"
      >
        {/* {dataApp} */}
        {Object.keys(dataApp).length === 0 && dataApp.constructor === Object ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
              />
            </svg>
            <span>YourShop</span>
          </>
        ) : (
          <>
            <img
              src={`${imgLogoUrl}${dataApp?.companyLogo}`}
              className="rounded-full w-[40px] h-[40px]"
            />
            <span>{dataApp?.companyLogText}</span>
          </>
        )}
      </NavLink>
      <nav className="sticky top-3">
        {links.map((link, index) => (
          <div key={`link_${index}`}>
            <span className="hidden md:block">{link.title}</span>

            {link.listItems.map((item, index) => (
              <React.Fragment key={`sublink_${index}`}>
                <NavLink
                  key={`item_${index}`}
                  to={item.url}
                  className={
                    pathname === item.url ? activeClasses : inactiveClasses
                  }
                >
                  <MyTooltip message={item.title}>{item.icon}</MyTooltip>
                  <span className="hidden md:block"> {item.title}</span>
                </NavLink>
              </React.Fragment>
            ))}
          </div>
        ))}
      </nav>
      {/*       <nav className="sticky top-3">
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={link.url}
            className={pathname === link.url ? activeClasses : inactiveClasses}
          >
            {link.icon}
            <span className="hidden md:block">{link.label}</span>
          </NavLink>
        ))}
      </nav> */}
      {/*       <nav className="sticky top-3">
        <div className="flex flex-col gap-2.5 mb-5">
          {menu.map((item) => (
            <div className="" key={item.id}>
              <span className="hidden md:block">{item.title}</span>
              {item.listItems.map((listItem) => (
                <Link
                  to={listItem.url}
                  className="flex items-center p-5 gap-5"
                  key={listItem.id}
                >
                  <img src={listItem.icon} alt="" />
                  <span className="hidden md:block">{listItem.title}</span>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </nav> */}
    </aside>
  );
};

export default Navigation;
