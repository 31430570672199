import React from "react";
import { WelcomeRow } from "../components";
import { Outlet, Link } from "react-router-dom";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

import { CiCircleList } from "react-icons/ci";
import {
  FiList,
  FiUserPlus,
  FiPrinter,
  FiTrash2,
  FiUsers,
} from "react-icons/fi";

import {
  MdOutlineGroupAdd,
  MdOutlineGroup,
  MdOutlineHandshake,
  MdAddchart,
  MdPermIdentity,
  MdAdd,
  MdFormatListBulleted,
  MdGridView,
  MdOutlineAccountBox,
  MdOutlineAccountCircle,
  MdOutlineAddCard,
  MdOutlinePrint,
} from "react-icons/md";

const MainOccupation = () => {
  return (
    <>
      <WelcomeRow />
      <div className="mb-6 items-center">
        <div className="flex items-center justify-end gap-1  rounded-md overflow-hidden">
          <Link to={""} className="btntrans">
            <BiSolidGridAlt size={22} />
          </Link>
          <Link to={"addmainocc"} className="btnblue">
            <BsPlusCircle size={22} />
          </Link>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default MainOccupation;
