import React from "react";
import { WelcomeRow } from "../components";
import { Outlet, Link } from "react-router-dom";
// import { IconName } from "react-icons/ai";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";
const Blog = () => {
  return (
    <>
      <WelcomeRow />
      <div className="mb-6 items-center">
        <div className="flex items-center justify-end gap-1 md:p-4 rounded-md overflow-hidden">
          <Link to={""} className="btntrans">
            <BiSolidGridAlt size={22} />
          </Link>
          <Link to={"addblog"} className="btnblue">
            <BsPlusCircle size={22} />
          </Link>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Blog;
