import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const WelcomeRow = () => {
  const location = useLocation();
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    var id = localStorage.getItem("id");
    var user = localStorage.getItem("fullname");
    console.log("user: ", user);
    setAuth(user);
    setUserId(id);
    if (user === null) {
      navigate("/login");
    }
  }, []);

  const LogOut = () => {
    // setIsClicked(initialState);
    localStorage.removeItem("id");
    localStorage.removeItem("fullname");
    localStorage.removeItem("email");
    localStorage.removeItem("ispolicy");
    localStorage.removeItem("acType");
    localStorage.removeItem("incomeperc");
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div className="flex mb-6 items-center">
      <div className="w-1/2 grow">
        <h1 className="text-primary text-xl">
          Welcome, <b>{auth ? auth : ""}</b>
        </h1>
      </div>
      {/*       <div className="">
        <div className="bg-gray-200 flex items-center rounded-md overflow-hidden">
          <img
            className="h-8"
            src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
            alt=""
          />
          <span className="px-3">Dawid</span>
        </div>
      </div> */}
      <button to={"/login"} onClick={LogOut}>
        <span className="bg-gray-200 flex items-center rounded-md overflow-hidden px-2 py-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
            />
          </svg>

          <span className="hidden md:block">Signout</span>
        </span>
      </button>
    </div>
  );
};

export default WelcomeRow;
