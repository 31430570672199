import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  bankCompanyUrl,
  imgBankUrl,
  bankUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const EditCompanyBank = () => {
  //bankCompanyUrl,imgBankUrl,bankUrl
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [userId, setUserId] = useState("");
  const [bankNameEdit, setBankNameEdit] = useState("");
  const [bankNoEdit, setBankNoEdit] = useState("");
  const [accountNameEdit, setAccountNameEdit] = useState("");
  const [branchNameEdit, setBranchNameEdit] = useState("");
  const [selectedFile, setSectedFile] = useState(null);
  const [dataBank, setDataBank] = useState([]);

  function readBank() {
    axios.get(`${bankUrl}readbankname.php`).then(function (response) {
      // console.log(response.data);
      setDataBank(response.data);
    });
  }

  function readData(id) {
    axios.get(`${bankCompanyUrl}readbyid.php/${id}`).then(function (response) {
      console.log("-------------" + id);
      console.log(response.data);
      setBankNameEdit(response.data?.bankName);
      setBankNoEdit(response.data?.bankNo);
      setAccountNameEdit(response.data?.accountName);
      setBranchNameEdit(response.data?.branchName);
      console.log("-------------");
      setDatas(response.data);
    });
  }

  useEffect(() => {
    var uid = localStorage.getItem("id");
    setUserId(uid);
    readBank();
    readData(id);
  }, []);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("banknoEdit", bankNoEdit);
    formData.append("banknameEdit", bankNameEdit);
    formData.append("accountnameEdit", accountNameEdit);
    formData.append("branchName", branchNameEdit);
    formData.append("idEdit", id);
    setLoading(true);

    axios
      .post(`${bankCompanyUrl}update.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };

  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.photo !== "" ? (
            <img
              src={`${imgBankUrl}${datas?.photo}`}
              className="rounded-lg mb-4 h-[300px] object-contain"
            />
          ) : null}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              ชื่อธนาคาร
            </label>
            <select
              value={bankNameEdit}
              onChange={(e) => setBankNameEdit(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {dataBank.map((item, index) => (
                <option key={index} value={item.thName}>
                  {item.thName}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              เลขบัญชี
            </label>
            <input
              type="tel"
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="เลขบัญชี"
              name="bankNo"
              value={bankNoEdit}
              onChange={(e) => setBankNoEdit(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              ชื่อบัญชี
            </label>
            <input
              type="text"
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="ชื่อบัญชี"
              name="accountName"
              value={accountNameEdit}
              onChange={(e) => setAccountNameEdit(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              สาขา
            </label>
            <input
              type="text"
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="สาขา"
              name="branchName"
              value={branchNameEdit}
              onChange={(e) => setBranchNameEdit(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditCompanyBank;
