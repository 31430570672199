import React from "react";
import { WelcomeRow } from "../components";
import { Outlet, Link } from "react-router-dom";

const Package = () => {
  return (
    <>
      <WelcomeRow />
      <div className="mb-6 items-center">
        <div className="flex items-center justify-end gap-1  rounded-md overflow-hidden">
          <Link to={""} className="btntrans">
            แสดงข้อมูล
          </Link>
          <Link to={"addpic"} className="btnblue">
            เพิ่มข้อมูล
          </Link>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Package;
