import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  govUrl,
  pvUrl,
  uUrl,
  imgGovUrl,
  imgPvUrl,
  imgUnUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const EditGov = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [scope, setScope] = useState("");
  const [url, setUrl] = useState("");
  const [agency, setAgency] = useState("");

  function readData(id) {
    axios.get(`${govUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setScope(response.data?.scope);
      setUrl(response.data?.url);
      setAgency(response.data?.agency);
      // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
    });
  }

  useEffect(() => {
    readData(id);
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("scope", scope);
    formData.append("url", url);
    formData.append("agency", agency);
    formData.append("idEdit", id);
    setLoading(true);
    axios.post(`${govUrl}update.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.photo != null ? (
            <img
              src={`${imgGovUrl}${datas?.photo}`}
              className="rounded-lg mb-4 md:h-[300px] object-cover"
            />
          ) : (
            "-"
          )}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Url Web site</label>
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Agency</label>
            <input
              type="text"
              value={agency}
              onChange={(e) => setAgency(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditGov;
