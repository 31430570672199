import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { settingsTypeUrl, partnerUrl, gfUserUrl } from "../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import { Card } from "../components";

import Modal from "react-modal";

import Swal from "sweetalert2";

const Signin = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [user, setUser] = useState({ email: "", password: "" });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values) => ({ ...values, [name]: value }));
  };
  const handleOnSubmit = (data) => {
    axios.post(`${gfUserUrl}login.php`, data).then(function (response) {
      console.log(response.data);
      if (response.data.status === 1) {
        window.localStorage.setItem("id", response.data.user.id);
        window.localStorage.setItem("email", response.data.user.email);
        window.localStorage.setItem("ispolicy", response.data.user.ispolicy);
        window.localStorage.setItem("acType", "GF");
        window.localStorage.setItem(
          "incomeperc",
          response.data.user.incomeperc
        );
        window.localStorage.setItem(
          "fullname",
          response.data.user.firstname + " " + response.data.user.lastname
        );
        if (response.data.user.ispolicy != 0) {
          navigate("/");
        } else {
          navigate("/policy");
        }
        // navigate("/");
        // navigate("/");
      }
    });
  };
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-purple-700">
          GiftLikes
        </h1>
        <form onSubmit={handleSubmit(handleOnSubmit)} className="mt-6">
          <div className="mb-2">
            <label className="block text-sm font-semibold text-gray-800">
              E-mail
            </label>
            <input
              type="email"
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
              {...register("email", {
                required: true,
              })}
            />
            {errors.email && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Email
              </div>
            )}
          </div>
          <div className="mb-2">
            <label className="block text-sm font-semibold text-gray-800">
              Password
            </label>
            <input
              type="password"
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
              {...register("password", {
                required: true,
              })}
            />
            {errors.password && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Password
              </div>
            )}
          </div>
          <Link to="/" className="text-xs text-purple-600 hover:underline">
            Forgot Password?
          </Link>
          <div className="mt-6">
            <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
              Signin
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signin;
