import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const EditMainOcc = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [thName, setThName] = useState("");
  const [enName, setEnName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  // const [emergency, setEmergency] = useState(false);
  const handleCbOnChange = () => {
    setIsChecked(!isChecked);
  };
  function readData(id) {
    axios
      .get(`${mainOccUrl}readmainoccbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setThName(response.data?.thName);
        setEnName(response.data?.enName);
        const cb = response.data?.emergency == 0 ? true : false;
        // setEmergency();
        setIsChecked(cb);
        // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
      });
  }

  useEffect(() => {
    readData(id);
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    // setLoading(true);
    // alert(isChecked);
    // const emergency = isChecked ? 0 : 1;
    // alert(emergency);
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("thName", thName);
    formData.append("enName", enName);
    formData.append("emergency", isChecked ? 0 : 1);
    formData.append("idEdit", id);

    axios
      .post(`${mainOccUrl}updatemainocc.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.photo != null ? (
            <img
              src={`${imgmainOccUrl}${datas?.photo}`}
              className="rounded-lg mb-4 md:h-[300px]"
            />
          ) : (
            "-"
          )}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Name</label>
            <input
              type="text"
              value={thName}
              onChange={(e) => setThName(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>En Name</label>
            <input
              type="text"
              value={enName}
              onChange={(e) => setEnName(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                checked={isChecked}
                onChange={handleCbOnChange}
                className="
    appearance-none w-4 h-4 border-2 border-blue-500 rounded-sm bg-white
    mt-1 shrink-0
    checked:bg-blue-800 checked:border-0"
              />
              <label htmlFor="some_id">
                Emergency({isChecked ? "Yes" : "No"})
              </label>
            </div>
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditMainOcc;
