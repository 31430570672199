import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const AddMemberLogo = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      componyLogo: "",
      url: "",
    },
  });
  //companylogo id,componyName,photo,created_at
  //laborlogo id,title,photo,discription,url,created_at
  //govadvert id,photo,scope,url,agency,status
  const [loading, setLoading] = useState(false);
  const [dataType, setDataType] = useState([]);
  function readTypeData() {
    //companylogo id,componyName,photo,created_at
    axios.get(`${logoUrl}readcompanylogo.php`).then(function (response) {
      console.log(response.data);
      setDataType(response.data);
    });
  }

  useEffect(() => {
    readTypeData();
  }, []);

  const handleOnSubmit = (data) => {
    /*     event.preventDefault();
        
            console.log(inputs); */
    //http://localhost/providerApi/gfincome/    pltype: pltype,
    setLoading(true);
    axios
      .post(`${logoUrl}addmycompanylogo.php`, {
        componyLogo: data.componyLogo,
        url: data.url,
        memberID: id,
      })
      .then(function (response) {
        console.log(response.data);
        // navigate("/gfincome");
        readTypeData();
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="mb-2">
          <label>Logo</label>
          <select
            {...register("componyLogo", {
              required: true,
            })}
            className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
          >
            <option value=""></option>
            {/* <option value="0">ไม่ระบุ</option> */}
            {dataType.map((item, index) => (
              <option key={index} value={item.photo}>
                {item.componyName}
              </option>
            ))}
          </select>
          {errors.componyLogo && (
            <div className="my-3 text-normal text-red-500 text-xs italic">
              Enter Logo
            </div>
          )}
        </div>
        <div className="mb-2">
          <label>URL:</label>
          <div className="flex flex-col items-start">
            <input
              type="url"
              name="url"
              {...register("url", {
                required: true,
              })}
            />
            {errors.url && (
              <div className="my-3 text-normal text-red-500 text-xs italic ">
                Enter URL
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button disabled={loading} type="submit" className="btnblue">
            {loading ? <>Please wait..</> : <>Save</>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddMemberLogo;
