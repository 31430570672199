import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  bankCompanyUrl,
  imgBankUrl,
  bankUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddCompanyBank = () => {
  //bankCompanyUrl,imgBankUrl,bankUrl
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [dataBank, setDataBank] = useState([]);
  const [selectedFile, setSectedFile] = useState(null);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bankName: "",
      bankNo: "",
      accountName: "",
      branchName: "",
    },
  });

  function readBank() {
    axios.get(`${bankUrl}readbankname.php`).then(function (response) {
      // console.log(response.data);
      setDataBank(response.data);
    });
  }

  useEffect(() => {
    var id = localStorage.getItem("id");
    setUserId(id);
    readBank();
  }, []);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("userId", userId);
    formData.append("bankNo", data.bankNo);
    formData.append("bankName", data.bankName);
    formData.append("accountName", data.accountName);
    formData.append("branchName", data.branchName);
    axios.post(`${bankCompanyUrl}add.php`, formData).then(function (response) {
      console.log(response.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      reset();

      setLoading(false); // Stop loading
    });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              ชื่อธนาคาร
            </label>
            <select
              {...register("bankName", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {dataBank.map((item, index) => (
                <option key={index} value={item.thName}>
                  {item.thName}
                </option>
              ))}
            </select>
            {errors.bankName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนธนาคาร.
              </div>
            )}
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              เลขบัญชี
            </label>
            <input
              type="text"
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="เลขบัญชี"
              name="bankNo"
              {...register("bankNo", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.bankNo && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนเลขบัญชี
              </div>
            )}
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              ชื่อบัญชี
            </label>
            <input
              type="text"
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="ชื่อบัญชี"
              name="accountName"
              {...register("accountName", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.accountName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนชื่อบัญชี
              </div>
            )}
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              สาขา
            </label>
            <input
              type="text"
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="สาขา"
              name="branchName"
              {...register("branchName", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.branchName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนสาขา
              </div>
            )}
          </div>

          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddCompanyBank;
