import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { gfSalepageUrl, gfSalepageFileUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const EditSalepage = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [selectedTwFile, setSectedTwFile] = useState(null);
  const [selectedTrFile, setSectedTrFile] = useState(null);
  /*   const [incomes, setIncomes] = useState({});
  const [datas, setDatas] = useState({}); */
  const [userId, setUserId] = useState("");
  const [lineurl, setLineurl] = useState("");
  const [fburl, setFburl] = useState("");
  const [phone, setPhone] = useState("");
  const [weburl, setWeburl] = useState("");

  function readData(id) {
    axios
      .get(`${gfSalepageUrl}readsalepagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setLineurl(response.data?.lineurl);
        setFburl(response.data?.fburl);
        setPhone(response.data?.phone);
        setWeburl(response.data?.weburl);
        // setThName(response.data?.thName);
        // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
      });
  }

  useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
    readData(id);
  }, []);

  const handleOnChange = (e) => {
    /*     console.log("----------");
        console.log(e.target.files[0]);
        console.log("----------"); */
    setSectedFile(e.target.files[0]);
  };

  const handleTwOnChange = (e) => {
    /*     console.log("----------");
        console.log(e.target.files[0]);
        console.log("----------"); */
    setSectedTwFile(e.target.files[0]);
  };
  const handleTrOnChange = (e) => {
    /*     console.log("----------");
        console.log(e.target.files[0]);
        console.log("----------"); */
    setSectedTrFile(e.target.files[0]);
  };
  // gfSalepageUrl,gfSalepageFileUrl

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("fileTwData", selectedTwFile);
    formData.append("fileTrData", selectedTrFile);
    formData.append("lineurl", lineurl);
    formData.append("phone", phone);
    formData.append("pnId", userId);
    formData.append("fburl", fburl);
    formData.append("weburl", weburl);
    formData.append("salepageId", "0");
    formData.append("idEdit", id);
    axios
      .post(`${gfSalepageUrl}editsalepage.php`, formData)
      .then(function (response) {
        console.log(response.data);
        setSectedFile(null);
        setSectedTwFile(null);
        setSectedTrFile(null);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="container mx-auto flex flex-col w-full md:flex-row flex-wrap">
      <div className="w-full grid grid-cols-1 gap-y-1 md:w-1/2">
        {datas?.picture != "" ? (
          <img
            src={`${gfSalepageFileUrl}${datas?.picture}`}
            className="rounded-lg object-cover"
          />
        ) : (
          ""
        )}
        {datas?.picture2 != "" ? (
          <img
            src={`${gfSalepageFileUrl}${datas?.picture2}`}
            className="rounded-lg object-cover"
          />
        ) : (
          ""
        )}
        {datas?.picture3 != "" ? (
          <img
            src={`${gfSalepageFileUrl}${datas?.picture3}`}
            className="rounded-lg object-cover"
          />
        ) : (
          ""
        )}
      </div>
      <div className="w-full p-2 md:w-1/2">
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Line ID</label>
            <input
              type="text"
              value={lineurl}
              onChange={(e) => setLineurl(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Facebook</label>
            <input
              type="text"
              value={fburl}
              onChange={(e) => setFburl(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Website</label>
            <input
              type="text"
              value={weburl}
              onChange={(e) => setWeburl(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Phone Number</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label>Image 1</label>
            <input type="file" onChange={handleOnChange} />
          </div>
          <div className="mb-4">
            <label>Image 2</label>
            <input type="file" onChange={handleTwOnChange} />
          </div>
          <div className="mb-4">
            <label>Image 3</label>
            <input type="file" onChange={handleTrOnChange} />
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSalepage;
