import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
  gfIncomeUrl,
  yuSalepageUrl,
  pltype,
  mPackageUrl,
  statusUrl,
  buyPackageUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const AddMPackage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      shortdetail: "",
      longdetail: "",
      price: "",
      period: "",
      unit: "",
      pkfor: "",
      pktype: "",
    },
  });
  //buyPackageUrl
  // mpackage id,title,shortdetail,longdetail,price,period,unit,created_at
  //salepageyoutube id,thName,url,created_at
  //C:\xampp\htdocs\appApl\settings\accounttype
  // console.log(`${settingsTypeUrl}addactype.php`);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [packageFor, setPackageFor] = useState([]);
  const [packageType, setPackageType] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };
  const [units, setUnits] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readUnit() {
    axios.get(`${mPackageUrl}readunit.php`).then(function (response) {
      console.log(response.data);
      setUnits(response.data);
    });
  }
  function readPkFor() {
    axios.get(`${statusUrl}readpkfor.php`).then(function (response) {
      console.log(response.data);
      setPackageFor(response.data);
    });
  }
  function readPkType() {
    axios.get(`${buyPackageUrl}readpackagetype.php`).then(function (response) {
      console.log(response.data);
      setPackageType(response.data);
    });
  }
  useEffect(() => {
    readUnit();
    readPkFor();
    readPkType();
  }, []);
  const handleOnSubmit = (data) => {
    setLoading(true);
    /*     title: data.title,
    shortdetail: data.shortdetail,
    longdetail: data.longdetail,
    price: data.price,
    period: data.period,
    unit: data.unit, */
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("title", data.title);
    formData.append("shortdetail", data.shortdetail);
    formData.append("longdetail", data.longdetail);
    formData.append("price", data.price);
    formData.append("period", data.period);
    formData.append("unit", data.unit);
    formData.append("packagefor", data.pkfor);
    formData.append("packtype", data.pktype);
    setLoading(true);

    axios.post(`${mPackageUrl}add.php`, formData).then(function (response) {
      console.log(response.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      reset();
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
    /*     axios
      .post(`${mPackageUrl}add.php`, {
        title: data.title,
        shortdetail: data.shortdetail,
        longdetail: data.longdetail,
        price: data.price,
        period: data.period,
        unit: data.unit,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      }); */
  };
  return (
    <div className="w-full">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Package Type</label>
            <select
              {...register("pktype", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option>({item.enName}) */}
              {packageType.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
            {errors.pktype && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Package Type
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              name="title"
              {...register("title", {
                required: true,
              })}
            />
            {errors.title && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Title
              </div>
            )}
          </div>

          <div className="mb-2">
            <label>Short Description</label>
            <input
              type="text"
              name="shortdetail"
              {...register("shortdetail", {
                required: true,
              })}
            />
            {errors.shortdetail && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Short Description
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Description</label>
            <input
              type="text"
              name="longdetail"
              {...register("longdetail", {
                required: true,
              })}
            />
            {errors.longdetail && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Description
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Price</label>
            <input
              type="number"
              {...register("price", {
                required: true,
              })}
            />
            {errors.price && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Price
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-2">
              <label>Period</label>
              <input
                type="number"
                {...register("period", {
                  required: true,
                })}
              />
              {errors.period && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter Period
                </div>
              )}
            </div>
            {/*             <div className="mb-2">
              <label>Unit</label>
              <input
                type="text"
                {...register("unit", {
                  required: true,
                })}
              />
              {errors.unit && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter Unit
                </div>
              )}
            </div> */}
            <div className="mb-2">
              <label>Unit</label>
              <select
                {...register("unit", {
                  required: true,
                })}
                className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              >
                <option value=""></option>
                {/* <option value="0">ไม่ระบุ</option> */}
                {units.map((item, index) => (
                  <option key={index} value={item.unitname}>
                    {item.unitname}
                  </option>
                ))}
              </select>
              {errors.unit && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Select Unit
                </div>
              )}
            </div>
            <div className="mb-2">
              <label>For Privider/User</label>
              <select
                {...register("pkfor", {
                  required: true,
                })}
                className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              >
                <option value=""></option>
                {/* <option value="0">ไม่ระบุ</option> */}
                {packageFor.map((item, index) => (
                  <option key={index} value={item.flag}>
                    {item.title}( {item.flag})
                  </option>
                ))}
              </select>
              {errors.pkfor && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Select For Privider/User
                </div>
              )}
            </div>
            {/* ----  */}
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddMPackage;
