import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  imgAppInfoUrl,
  appinfoUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";

import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";
import Swal from "sweetalert2";

import { useNavigate, useParams } from "react-router-dom";

const EditAppInfo = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [appName, setAppName] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  //appinfo id,appName,appDiscription,photo,country
  //accounttype id,typename,description,flag,created_at
  function readData(id) {
    axios
      .get(`${appinfoUrl}readappinfobyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setAppName(response.data?.appName);
        setDescription(response.data?.appDiscription);
        setCountry(response.data?.country);
      });
  }

  useEffect(() => {
    readData(id);
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };
  /*   const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${appinfoUrl}updateappinfo.php`, {
        idEdit: id,
        appName,
        description,
        country,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      });
  }; */
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("appName", appName);
    formData.append("description", description);
    formData.append("country", country);
    formData.append("id", id);
    setLoading(true);
    /*     idEdit: id,
    appName,
    description,
    country, */
    // console.log(thName, enName);

    axios
      .post(`${appinfoUrl}updateappinfo.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.photo !== "" ? (
            <img
              src={`${imgAppInfoUrl}${datas?.photo}`}
              className="rounded-lg mb-4 h-[300px]"
            />
          ) : (
            "-"
          )}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>App Name</label>
            <input
              type="text"
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>App Discription</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Country</label>
            <input
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditAppInfo;
