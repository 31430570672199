import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  logoUrl,
  bankUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
} from "react-icons/bi";
import { FiSave } from "react-icons/fi";

const BankLogoAccount = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [bankname, setBankname] = useState("");
  const [bankno, setBankno] = useState("");
  const [bankbranch, setBankbranch] = useState("");
  const [bankacname, setBankAcname] = useState("");
  const [partnerData, setPartnerData] = useState({});
  const [dataType, setDataType] = useState([]);
  const [dataBank, setDataBank] = useState([]);
  function readBank() {
    axios.get(`${bankUrl}readbankname.php`).then(function (response) {
      // console.log(response.data);
      setDataBank(response.data);
    });
  }
  /*     bankname,bankno,
    bankbranch,bankacname, */
  function readTypeData() {
    axios.get(`${settingsTypeUrl}readactype.php`).then(function (response) {
      console.log(response.data);
      setDataType(response.data);
    });
  }

  /*   function readPartner(id) {
      axios.get(`${partnerUrl}readbyid.php/${id}`).then(function (response) {
        console.log(response.data);
        setPartnerData(response.data);
      });
    }
  
    useEffect(() => {
      readPartner(id);
      readTypeData();
    }, []); */
  //accounttype id,typename,description,flag,created_at
  function readData(id) {
    axios.get(`${logoUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setBankname(
        response.data?.bankname != null ? response.data?.bankname : ""
      );
      setBankno(response.data?.bankno != null ? response.data?.bankno : "");
      setBankbranch(
        response.data?.bankbranch != null ? response.data?.bankbranch : ""
      );
      setBankAcname(
        response.data?.bankacname != null ? response.data?.bankacname : ""
      );
      /*     bankname,bankno,
    bankbranch,bankacname, */
    });
  }

  useEffect(() => {
    readBank();
    readData(id);
    readTypeData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${logoUrl}updatebank.php`, {
        idEdit: id,
        bankname,
        bankno,
        bankbranch,
        bankacname,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
              Bank Name
            </label>
            <select
              value={bankname}
              onChange={(e) => setBankname(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {dataBank.map((item, index) => (
                <option key={index} value={item.thName}>
                  {item.thName}
                </option>
              ))}
            </select>
          </div>
          {/*           <div className="grid grid-cols-2 gap-4">
            <div className="mb-6">
              <label>Bank Name</label>
              <input
                type="text"
                value={bankname}
                onChange={(e) => setBankname(e.target.value)}
              />
            </div> */}

          <div className="mb-6">
            <label>Bank No</label>
            <input
              type="text"
              value={bankno}
              onChange={(e) => setBankno(e.target.value)}
            />
          </div>
          {/* </div> */}

          <div className="mb-6">
            <label>Brance</label>
            <input
              type="text"
              value={bankbranch}
              onChange={(e) => setBankbranch(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label>Bank Account</label>
            <input
              type="text"
              value={bankacname}
              onChange={(e) => setBankAcname(e.target.value)}
            />
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {/* <FiSave size={22} /> */}
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default BankLogoAccount;
