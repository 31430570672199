import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  introduceUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const PartnerSale = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      introduceId: "",
      incomeAmount: "",
    },
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  //   const [datas, setDatas] = useState([]);
  const [incomes, setIncomes] = useState([]);
  /*   const [partnerincomes, setPartnerIncomes] = useState([]);
  const [dataType, setDataType] = useState([]); */
  const [accountData, setAccountData] = useState([]);
  function readIncomeData() {
    axios.get(`${introduceUrl}readincome.php`).then(function (response) {
      console.log(response.data);
      setIncomes(response.data);
    });
  }

  function readAcData() {
    axios.get(`${introduceUrl}read.php`).then(function (response) {
      console.log(response.data);
      setAccountData(response.data);
    });
  }

  useEffect(() => {
    readIncomeData();
    readAcData();
  }, []);

  const handleOnSubmit = (data) => {
    setLoading(true);

    axios
      .post(`${partnerUrl}addincome.php`, {
        introduceId: data.introduceId,
        incomeAmount: data.incomeAmount,
        partnerID: id,
      })
      .then(function (response) {
        console.log(response.data);
        // readPartnerIncome(id);
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        {/* <h2>Add New Partner</h2> */}
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 my-2 ml-1">
              ชื่อผู้นำพา
            </label>
            <select
              {...register("introduceId", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="">ไม่ระบุ</option> */}
              {accountData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.firstname}/{item.lastname}
                </option>
              ))}
            </select>
            {errors.introduceId && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ระบุผู้นำพา
              </div>
            )}
          </div>

          <div className="mb-2">
            <label>Income</label>
            <select
              {...register("incomeAmount", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {incomes.map((item, index) => (
                <option key={index} value={item.incomePercent}>
                  {item.incomeName}( {item.incomePercent})
                </option>
              ))}
            </select>
            {errors.income && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Select Income
              </div>
            )}
          </div>
          {/* ----  */}

          {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default PartnerSale;
