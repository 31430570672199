import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const CreateLogo = () => {
  // const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      componyName: "",
    },
  });
  //companylogo id,componyName,photo,created_at
  //laborlogo id,title,photo,discription,url,created_at
  //govadvert id,photo,scope,url,agency,status
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  /*   const [laborlogos, setLaborlogos] = useState([]);
  const [userId, setUserId] = useState(""); */
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  /*   function readLaborlogo() {
    axios.get(`${logoUrl}readlaborlogo.php`).then(function (response) {
      console.log(response.data);
      setLaborlogos(response.data);
    });
  }
  useEffect(() => {
    var id = localStorage.getItem("id");
    setUserId(id);
    readLaborlogo();
  }, []); */
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("componyName", data.componyName);

    setLoading(true);
    //laborlogo id,title,photo,discription,url,created_at
    axios
      .post(`${logoUrl}addcompanylogo.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
        //locations
      });
  };
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            {/*           <div className="mb-2">
        <label>Scope</label>
        <select
          {...register("scope", {
            required: true,
          })}
          className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
        >
          <option value=""></option>
          <option value="0">มองเห็นทั้งหมด</option>
          {locations.map((item, index) => (
            <option key={index} value={item.id}>
              {item.thName}
              {item.enName ? `(${item.enName})` : ""}-{item.id}
            </option>
          ))}
          
        </select>
        {errors.scope && (
          <div className="my-3 text-normal text-red-500 text-xs italic">
            Select Scope
          </div>
        )}
      </div> 
      laborlogo id,title,photo,discription,url,created_at
      */}
            <div className="mb-2 flex-1">
              <label>ชื่อหน่วยงานออกโลโก้</label>
              <input
                type="text"
                name="componyName"
                {...register("componyName", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.componyName && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนชื่อหน่วยงานออกโลโก้
                </div>
              )}
            </div>
            <div className="my-2">
              <label>รูปโลโก้</label>
              <div className="flex flex-col items-start">
                <input type="file" onChange={handleOnChange} />
              </div>
            </div>
            <div className="flex justify-end">
              <button disabled={loading} type="submit" className="btnblue">
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateLogo;
