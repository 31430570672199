import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { settingsTypeUrl, partnerUrl, logoUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const ViewLogo = () => {
  const [loading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState([]);

  function readPartner() {
    axios.get(`${logoUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPartnerData(response.data);
    });
  }

  useEffect(() => {
    readPartner();
  }, []);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${logoUrl}delete.php/${id}`).then(function (response) {
          console.log(response.data);
          readPartner();
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };
  return (
    <div>
      {partnerData.map((item, index) => (
        <div key={index} className="">
          <Card>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2">
                <h2 className="font-semibold">
                  <strong>Full name:</strong> {item?.firstname} {item?.lastname}
                  ({item?.logotype})
                </h2>
                {/* <p>{item?.description}</p> */}
                <h2 className="text-sm">
                  <strong>Phone:</strong> {item?.phone}
                </h2>
                <h2 className="text-sm">
                  <strong>E-mail:</strong> {item?.email}({item?.password})
                </h2>
                <h2 className="text-sm">
                  <strong>Bank No/Bank Name:</strong>{" "}
                  {item?.bankno != null ? item?.bankno : "-"}{" "}
                  {item?.bankname != null ? item?.bankname : "-"}
                </h2>
                <h2 className="text-sm">
                  <strong>Branch/Bank Account:</strong>{" "}
                  {item?.bankbranch != null ? item?.bankbranch : "-"}{" "}
                  {item?.bankacname != null ? item?.bankacname : "-"}
                </h2>
              </div>
              {/* //firstname,lastname,phone,email,password,pltype */}
              {/*                    bankname,bankno,
    bankbranch,bankacname,  */}
              <div className="mt-2 flex justify-end items-end">
                <button
                  // onClick={() => confirmDelete(item.id)}
                  onClick={() => deleteData(item.id)}
                  className="bg-red-500 mr-1 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                >
                  <IoTrashOutline size={22} />
                </button>

                <Link
                  to={`editaclogo/${item.id}`}
                  className="mr-1 bg-lime-500 hover:bg-lime-700 text-white text-center py-1 px-4 rounded"
                >
                  <HiMiniPencilSquare size={22} />
                </Link>
                <Link
                  to={`bankaclogo/${item.id}`}
                  className="mr-1 bg-blue-500 hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                >
                  <HiOutlineBanknotes size={22} />
                </Link>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ViewLogo;
