import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  pnSalepageUrl,
  picSalepageUrl,
  imgSalepageFileUrl,
  gfSalepageUrl,
  gfSalepageFileUrl,
  yuSalepageUrl,
  videoSalepageUrl,
  videoSalepageFileUrl,
} from "../../../utils/baseUrl";

// import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

/* import Modal from "react-modal";

import Swal from "sweetalert2"; */

// import ReactPlayer from "react-player";
import ReactPlayer from "react-player/lazy";

import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import { BiGlobe } from "react-icons/bi";
// import { BsFacebook } from "react-icons/bs";
import { AiOutlineFacebook, AiOutlineLink } from "react-icons/ai";
import { FaLine, FaRegIdBadge } from "react-icons/fa";

const PreviewSalepage = () => {
  const { id } = useParams();
  const [datas, setDatas] = useState({});
  const [salepages, setSalePagess] = useState([]);
  const [dataApp, setDataApp] = useState([]);
  const saleurl = "/salepage/";
  const regisurl = "/register/";

  const [dataYu, setDataYu] = useState([]);
  const [dataVideo, setDataVideo] = useState([]);

  function readYuData() {
    axios.get(`${yuSalepageUrl}randomyu.php`).then(function (response) {
      console.log(response.data);
      setDataYu(response.data);
    });
  }
  function readVideoData() {
    axios.get(`${videoSalepageUrl}randmyvideo.php`).then(function (response) {
      console.log(response.data);
      setDataVideo(response.data);
    });
  }

  function readAppData() {
    axios.get(`${picSalepageUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }
  //http://localhost:3000/previewsalepage/5
  // gfSalepageUrl,gfSalepageFileUrl
  function readDatas(id) {
    axios
      .get(`${gfSalepageUrl}readsalepagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
      });
  }

  /*   function readSalepages() {
    axios.get(`${pnSalepageUrl}readsalepageall.php`).then(function (response) {
      console.log(response.data);
      setSalePagess(response.data);
    });
  } */

  useEffect(() => {
    readDatas(id);
    readAppData();
    readYuData();
    readVideoData();
    // readSalepages();
  }, []);

  /*   pnSalepageUrl,
  pnSalepageFileUrl, */

  console.log("You: ", dataYu);
  console.log("You 0: ", dataYu[0]);
  console.log("You 1: ", dataYu[1]);
  console.log("You: ", dataVideo);
  console.log("You 0: ", dataVideo[0]);
  console.log("You 1: ", dataVideo[1]);

  return (
    <div className="w-full md:w-full lg:w-1/2 mx-auto">
      <Card>
        {/* <h2>{id}</h2> */}
        <div className="flex flex-col gap-2">
          {dataYu[0]?.url !== undefined ? (
            <div className="md:h-[300px]">
              <ReactPlayer
                controls={true}
                url={`${dataYu[0]?.url}`}
                width="100%"
                height="100%"
              />
            </div>
          ) : null}
          {dataVideo[0]?.video !== undefined ? (
            <div className="md:h-[300px]">
              <ReactPlayer
                controls={true}
                url={`${videoSalepageFileUrl}${dataVideo[0]?.video}`}
                width="100%"
                height="100%"
              />
            </div>
          ) : null}

          {datas.picture != "" ? (
            <>
              <img
                src={`${gfSalepageFileUrl}${datas.picture}`}
                className="rounded-lg mb-0 w-[100%]"
              />
              <div>
                {datas?.fburl != "-" ? (
                  <>
                    <Link
                      to={datas?.fburl}
                      className="flex items-center bg-blue-500 text-white hover:bg-blue-400 px-4 py-2  md:py-4 rounded-md mx-0 outline-none focus:shadow-outline md:w-full"
                    >
                      <img
                        src={require("../../../assets/images/s02.png")}
                        className="w-10 h-10 mr-4"
                      />{" "}
                      Facebook
                    </Link>
                  </>
                ) : null}
                {datas?.lineurl != "-" ? (
                  <>
                    <Link
                      to={datas?.lineurl}
                      className="flex my-2 items-center bg-neutral-500 text-white hover:bg-neutral-400 px-4 py-2  md:py-4 rounded-md mx-0 outline-none focus:shadow-outline"
                    >
                      <img
                        src={require("../../../assets/images/s03.png")}
                        className="w-10 h-10 mr-4"
                      />{" "}
                      {datas?.lineurl}
                    </Link>
                  </>
                ) : (
                  <></>
                )}
                {datas?.phone !== "-" ? (
                  <>
                    <Link
                      to={""}
                      className="flex items-center bg-indigo-500 text-white hover:bg-indigo-400 px-4 py-2  md:py-4 rounded-md mx-0 outline-none focus:shadow-outline w-full"
                    >
                      <img
                        src={require("../../../assets/images/s05.png")}
                        className="w-10 h-10 mr-4"
                      />{" "}
                      {datas?.phone}
                    </Link>
                  </>
                ) : (
                  <></>
                )}
                {/* {datas?.fburl != null ?(<></>):(<></>)} */}
              </div>
            </>
          ) : (
            ""
          )}
          {datas.picture2 != "" ? (
            <img
              src={`${gfSalepageFileUrl}${datas.picture2}`}
              className="rounded-lg mb-0 w-[100%]"
            />
          ) : (
            ""
          )}
          {datas.picture3 != "" ? (
            <img
              src={`${gfSalepageFileUrl}${datas.picture3}`}
              className="rounded-lg mb-0 w-[100%]"
            />
          ) : (
            ""
          )}
          {dataApp.map((item, index) => (
            <div key={index} className="">
              {item.photo !== "" ? (
                <img
                  src={`${imgSalepageFileUrl}${item.photo}`}
                  className="rounded-lg mb-0 w-[100%]"
                />
              ) : (
                "-"
              )}
            </div>
          ))}
          {datas.picture == "" ? (
            <>
              {datas?.fburl != "-" ? (
                <>
                  <Link
                    to={datas?.fburl}
                    className="flex items-center bg-blue-500 text-white hover:bg-blue-400 px-4 py-2  md:py-4 rounded-md mx-0 outline-none focus:shadow-outline md:w-full"
                  >
                    <img
                      src={require("../../../assets/images/s02.png")}
                      className="w-10 h-10 mr-4"
                    />{" "}
                    Facebook
                  </Link>
                </>
              ) : null}
              {datas?.lineurl != "-" ? (
                <>
                  <Link
                    to={datas?.lineurl}
                    className="flex my-2 items-center bg-neutral-500 text-white hover:bg-neutral-400 px-4 py-2  md:py-4 rounded-md mx-0 outline-none focus:shadow-outline"
                  >
                    <img
                      src={require("../../../assets/images/s03.png")}
                      className="w-10 h-10 mr-4"
                    />{" "}
                    {datas?.lineurl}
                  </Link>
                </>
              ) : (
                <></>
              )}
              {datas?.phone !== "-" ? (
                <>
                  <Link
                    to={""}
                    className="flex items-center bg-indigo-500 text-white hover:bg-indigo-400 px-4 py-2  md:py-4 rounded-md mx-0 outline-none focus:shadow-outline w-full"
                  >
                    <img
                      src={require("../../../assets/images/s05.png")}
                      className="w-10 h-10 mr-4"
                    />{" "}
                    {datas?.phone}
                  </Link>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {dataYu[1]?.url !== undefined ? (
            <div className="md:h-[300px]">
              <ReactPlayer
                controls={true}
                url={`${dataYu[1]?.url}`}
                width="100%"
                height="100%"
              />
            </div>
          ) : null}
          {dataVideo[1]?.video !== undefined ? (
            <div className="md:h-[300px]">
              <ReactPlayer
                controls={true}
                url={`${videoSalepageFileUrl}${dataVideo[1]?.video}`}
                width="100%"
                height="100%"
              />
            </div>
          ) : null}
        </div>
        {/* ------- */}
      </Card>
    </div>
  );
};

export default PreviewSalepage;
