import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  govUrl,
  pvUrl,
  uUrl,
  imgGovUrl,
  imgPvUrl,
  imgUnUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const EditUnit = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [scope, setScope] = useState("");
  const [phone, setPhone] = useState("");
  const [url, setUrl] = useState("");
  const [agency, setAgency] = useState("");
  const [locations, setLocations] = useState([]);

  function readLocation() {
    axios.get(`${uUrl}location.php`).then(function (response) {
      console.log(response.data);
      setLocations(response.data);
    });
  }

  function readData(id) {
    axios.get(`${uUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setScope(response.data?.scope);
      setUrl(response.data?.url);
      setAgency(response.data?.agency);
      // setScope(response.data?.scope);
      // setMyPackage(response.data?.packageID);
      // setUrl(response.data?.url);
      setPhone(response.data?.phone);
      // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
    });
  }

  useEffect(() => {
    readData(id);
    readLocation();
  }, []);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("scope", scope);
    formData.append("url", url);
    formData.append("agency", agency);
    formData.append("phone", phone);
    formData.append("idEdit", id);
    setLoading(true);
    /*   scope: "",
  url: "",
  agency: "", */
    axios.post(`${uUrl}update.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.photo != null ? (
            <img
              src={`${imgUnUrl}${datas?.photo}`}
              className="rounded-lg mb-4 md:h-[300px] object-cover"
            />
          ) : (
            "-"
          )}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Scope-{id}</label>
            <select
              value={scope}
              onChange={(e) => setScope(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            >
              <option value=""></option>
              <option value="0">All</option>
              {locations.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.thName}
                  {item.enName ? `(${item.enName})` : ""}-{item.id}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2">
            <label>Url Web site</label>
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Email ลูกค้า</label>
            <input
              type="text"
              value={agency}
              onChange={(e) => setAgency(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Phone*</label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="my-2">
            <label>Photo</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditUnit;
