import React from "react";
import { Link, Outlet } from "react-router-dom";
import { WelcomeRow } from "../components";
const Settings = () => {
  return (
    <>
      <WelcomeRow />
      <h2>Setting</h2>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Settings;
