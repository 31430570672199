import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import UploadImg from "../../assets/img/icons/upload.png";
import { blogUrl, imgBlogUrl } from "../../utils/baseUrl";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineDocumentAdd } from "react-icons/hi";

import Card from "./../Card";
const ViewBlog = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [promoData, setPromoData] = useState([]);
  function readData() {
    axios.get(`${blogUrl}read.php`).then(function (response) {
      console.log(response.data);
      setPromoData(response.data);
    });
  }

  useEffect(() => {
    readData();
  }, []);

  const deleteData = (id) => {
    Swal.fire({
      title: "ยืนยัน?",
      text: "ต้องการลบข้อมูล!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ใช่",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${blogUrl}delete.php/${id}`).then(function (response) {
          console.log(response.data);
          readData();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "ลบข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };
  return (
    <div className="w-full ">
      <Card>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            {/* ------  lg:col-span-4 */}

            {/* ---- */}
            <div className="bg-white rounded-t-md rounded-b-md shadow-xs">
              <div className="tp-search-box flex items-center justify-between px-8 mt-4"></div>
              <div className="relative p-4 overflow-x-auto">
                {/* ---------  */}
                <table className="w-full text-base text-left text-gray-500">
                  <thead className="bg-white">
                    <tr className="border-b border-gray6">
                      <th scope="col" className="font-semibold w-[25%]">
                        Picture
                      </th>
                      <th scope="col" className="font-semibold">
                        Discription
                      </th>
                      <th scope="col" className="font-semibold w-[20%]">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {promoData.map((item, idx) => (
                      <tr
                        key={`promo_${idx}`}
                        className="bg-white border-b border-gray6 last:border-0 text-start mx-9"
                      >
                        <td className="p-2">
                          <img
                            className={`h-[100px] md:h-[150px] rounded-xl object-cover`}
                            src={`${imgBlogUrl}${item.photo}`}
                            alt=""
                          />
                        </td>
                        <td className="font-normal text-[#55585B]">
                          <p className="mb-2">
                            <strong>{item?.title}</strong>
                          </p>
                          <p>{item?.description}</p>
                        </td>
                        <td className="">
                          <div className="flex items-center space-x-2">
                            {/*                 <Link  to={`addbloggallery/${item?.id}`} className="w-10 h-10 flex items-center justify-center leading-10 text-tiny bg-success text-white rounded-md hover:bg-green-600">
                <FaImage size={30}/>
                </Link> */}
                            <Link
                              to={`addblogcontent/${item?.id}`}
                              className="px-2 h-10 flex items-center justify-center leading-10 text-tiny bg-success text-white rounded-md hover:bg-green-600"
                            >
                              <HiOutlineDocumentAdd size={30} />
                              Add Sub Blog
                            </Link>
                            <Link
                              to={`editblog/${item?.id}`}
                              className="w-10 h-10 flex items-center justify-center leading-10 text-tiny bg-success text-white rounded-md hover:bg-green-600"
                            >
                              <GrEdit size={30} />
                            </Link>
                            <button
                              onClick={() => deleteData(item?.id)}
                              className="w-10 h-10 flex items-center justify-center leading-[33px] text-tiny bg-white border border-gray text-slate-600 rounded-md hover:bg-danger hover:border-danger hover:text-white"
                            >
                              <RiDeleteBin6Line size={30} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* ---------  */}
              </div>
            </div>
            {/* ---- */}

            {/* ------  */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ViewBlog;
