import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { officesGalleryUrl, imgOfficeGalleryUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const EditGallery = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [title, setTitle] = useState("");
  const [selectedFile, setSectedFile] = useState(null);
  const [selectedTwFile, setSectedTwFile] = useState(null);
  const handleOnChange = (e) => {
    /*     console.log("----------");
          console.log(e.target.files[0]);
          console.log("----------"); */
    setSectedFile(e.target.files[0]);
  };

  const handleTwOnChange = (e) => {
    /*     console.log("----------");
          console.log(e.target.files[0]);
          console.log("----------"); */
    setSectedTwFile(e.target.files[0]);
  };

  function readData(id) {
    axios
      .get(`${officesGalleryUrl}readbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setTitle(response.data?.title);
        // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
      });
  }

  useEffect(() => {
    readData(id);
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("fileTwData", selectedTwFile);
    formData.append("title", title);

    formData.append("idEdit", id);
    setLoading(true);

    axios
      .post(`${officesGalleryUrl}update.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        setTitle("");
        setSectedFile(null);
        setSectedTwFile(null);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <div>
          {datas?.photo_1 !== null ? (
            <img
              src={`${imgOfficeGalleryUrl}${datas.photo_1}`}
              className="rounded-lg mb-4 lg:h-[300px]"
            />
          ) : null}
          {datas?.photo_2 !== null ? (
            <img
              src={`${imgOfficeGalleryUrl}${datas.photo_2}`}
              className="rounded-lg mb-4 lg:h-[300px]"
            />
          ) : null}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label>Photo 1</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="my-2">
            <label>Photo 2</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleTwOnChange} />
            </div>
          </div>
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditGallery;
