import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css

import ReactPlayer from "react-player/lazy";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const ViewVideo = () => {
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${videoSalepageUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${videoSalepageUrl}del.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readAppData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      }
    });
  };
  return (
    <div>
      {/* locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at */}
      {dataApp.map((item, index) => (
        <div key={index} className="w-full">
          <Card>
            <div className="flex flex-col gap-2 md:flex-row relative">
              <div className="w-full flex flex-col gap-4 md:flex-row">
                <div className="md:h-[100px]">
                  {item?.video !== "" ? (
                    <>
                      <ReactPlayer
                        controls={true}
                        url={`${videoSalepageFileUrl}${item?.video}`}
                        width="100%"
                        height="100%"
                      />
                    </>
                  ) : null}
                </div>
                <div className="w-full">
                  <h2 className="font-semibold">{item?.thName}</h2>
                  <p>
                    {item?.isDisplay == "1" ? "Show(แสดง)" : "Hidden(ซ่อน)"}
                  </p>
                </div>
              </div>
              <div className="mt-2 w-full md:w-1/2 flex justify-end items-end absolute top-1 right-0">
                <button
                  onClick={() => deleteData(item.id)}
                  className="bg-red-500 mr-4 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                >
                  <IoTrashOutline size={22} />
                </button>

                <Link
                  to={`editvideo/${item.id}`}
                  className="mr-1 bg-blue-500 hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                >
                  <HiMiniPencilSquare size={22} />
                </Link>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ViewVideo;
