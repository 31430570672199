import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { bankUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";

const AddBank = () => {
  //bankUrl
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      thName: "",
      enName: "",
    },
  });
  // bankname id,thName,enName,created_at
  //C:\xampp\htdocs\appApl\settings\accounttype
  // console.log(`${settingsTypeUrl}addactype.php`);
  const [loading, setLoading] = useState(false);
  const handleOnSubmit = (data) => {
    setLoading(true);

    axios
      .post(`${bankUrl}add.php`, {
        thName: data.thName,
        enName: data.enName,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        // toast.success("Successfully created!");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Bank Name</label>
            <input
              type="text"
              // placeholder="ชื่อจริง"
              name="thName"
              {...register("thName", {
                required: true,
              })}
            />
            {errors.thName && (
              <div className="my-3 text-sm italic text-red-500">Enter Name</div>
            )}
          </div>

          <div className="mb-2">
            <label>En name</label>
            <input
              type="text"
              // placeholder="นามสกุลจริง"
              name="enName"
              {...register("enName", {
                required: true,
              })}
            />
            {errors.enName && (
              <div className="my-3 text-sm italic text-red-500">
                Enter En Name
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        {/* <Toaster /> */}
      </Card>
    </div>
  );
};

export default AddBank;
