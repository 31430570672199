import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  gfUserUrl,
  gfIncomeUrl,
  pltype,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const AddIncome = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      incomeName: "",
      amountPercent: "",
      incomeAmount: "0",
      pltype: "",
    },
  });

  const [loading, setLoading] = useState(false);

  const [dataType, setDataType] = useState([]);

  function readTypeData() {
    axios.get(`${settingsTypeUrl}readactype.php`).then(function (response) {
      console.log(response.data);
      setDataType(response.data);
    });
  }

  useEffect(() => {
    readTypeData();
  }, []);

  const handleOnSubmit = (data) => {
    /*     event.preventDefault();
        
            console.log(inputs); */
    //http://localhost/providerApi/gfincome/    pltype: pltype,
    setLoading(true);
    axios
      .post(`${gfIncomeUrl}add.php`, {
        incomeName: data.incomeName,
        amountPercent: data.amountPercent,
        incomeAmount: data.incomeAmount,
        pltype: data.pltype,
      })
      .then(function (response) {
        console.log(response.data);
        // navigate("/gfincome");
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full lg:w-1/2 lg:ml-auto">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Income Type</label>
            <select
              {...register("pltype", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {dataType.map((item, index) => (
                <option key={index} value={item.flag}>
                  {item.typename}
                </option>
              ))}
            </select>
            {errors.pltype && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter Income Type
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Title:</label>
            <div className="flex flex-col items-start">
              <input
                type="text"
                name="incomeName"
                {...register("incomeName", {
                  required: true,
                })}
              />
              {errors.incomeName && (
                <div className="my-3 text-normal text-red-500 ">
                  Enter Title
                </div>
              )}
            </div>
          </div>
          <div className="mb-2">
            <label>Percent(%):</label>
            <div className="flex flex-col items-start">
              <input
                type="text"
                name="amountPercent"
                {...register("amountPercent", {
                  required: true,
                })}
              />
              {errors.amountPercent && (
                <div className="my-3 text-normal text-red-500 ">
                  Enter Percent(%)
                </div>
              )}
            </div>
          </div>
          <div className="mb-2">
            <label>Amount:</label>
            <div className="flex flex-col items-start">
              <input
                type="text"
                name="incomeAmount"
                {...register("incomeAmount", {
                  required: true,
                })}
              />
              {errors.incomeAmount && (
                <div className="my-3 text-normal text-red-500 ">
                  Enter Amount
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddIncome;
