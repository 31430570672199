import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";

const AddDistribute = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      pltype: "",
    },
  });
  // partner id,firstname,lastname,phone,email,password,incomeperc,ispolicy,pltype,created_at
  // mpackage id,title,shortdetail,longdetail,price,period,unit,created_at
  //salepageyoutube id,thName,url,created_at
  //C:\xampp\htdocs\appApl\settings\accounttype
  // console.log(`${settingsTypeUrl}addactype.php`);
  const [loading, setLoading] = useState(false);
  const [dataType, setDataType] = useState([]);

  function readTypeData() {
    axios.get(`${settingsTypeUrl}readactype.php`).then(function (response) {
      console.log(response.data);
      setDataType(response.data);
    });
  }

  useEffect(() => {
    readTypeData();
  }, []);
  // const [units, setUnits] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  /*   function readUnit() {
    axios.get(`${mPackageUrl}readunit.php`).then(function (response) {
      console.log(response.data);
      setUnits(response.data);
    });
  }
  useEffect(() => {
    readUnit();
  }, []); */
  const handleOnSubmit = (data) => {
    setLoading(true);
    //firstname,lastname,phone,email,password,pltype
    axios
      .post(`${distributeUrl}add.php`, {
        firstname: data.firstname,
        lastname: data.lastname,
        phone: data.phone,
        email: data.email,
        password: data.password,
        pltype: data.pltype,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        // toast.success("Successfully created!");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="mx-auto md:w-1/2 ">
      <Card>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-2">
            <label>Firstname</label>
            <input
              type="text"
              name="firstname"
              {...register("firstname", {
                required: true,
              })}
            />
            {errors.firstname && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter firstname
              </div>
            )}
          </div>
          {/* //firstname,lastname,phone,email,password,pltype */}
          <div className="mb-2">
            <label>Lastname</label>
            <input
              type="text"
              name="lastname"
              {...register("lastname", {
                required: true,
              })}
            />
            {errors.lastname && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter lastname
              </div>
            )}
          </div>

          <div className="mb-2">
            <label>Phone</label>
            <input
              type="tel"
              name="phone"
              {...register("phone", {
                required: true,
              })}
            />
            {errors.phone && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter phone
              </div>
            )}
          </div>

          <div className="mb-2">
            <label>Email</label>
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
              })}
            />
            {errors.email && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter email
              </div>
            )}
          </div>
          {/* //firstname,lastname,phone,email,password,pltype */}
          <div className="mb-2">
            <label>Password</label>
            <input
              type="password"
              name="password"
              {...register("password", {
                required: true,
              })}
            />
            {errors.password && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Enter password
              </div>
            )}
          </div>
          <div className="mb-2">
            <label>Account Type</label>
            <select
              {...register("pltype", {
                required: true,
              })}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {dataType.map((item, index) => (
                <option key={index} value={item.flag}>
                  {item.typename}( {item.flag})
                </option>
              ))}
            </select>
            {errors.pltype && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                Select Account Type
              </div>
            )}
          </div>
          {/* ----  */}

          {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddDistribute;
