import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  officesHerosUrl,
  officesCompanyUrl,
  officesPopularsHeaderUrl,
  officesPTUrl,
  imgOfficeUrl,
  officesFaqUrl,
} from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};

const AddPolicy = () => {
  //office_policy id,urlfiledownload,content,created_at
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [value, setValue] = useState("");
  const [policy, setPolicy] = useState(""); //policyTypeID
  const [policyType, setPolicyType] = useState([]);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  //center_content id,headflagID,title,subtitle,description,icon,photo,btn_link_1,btn_link_2,created_at
  function readPolicyType() {
    axios.get(`${officesPTUrl}readpolicytype.php`).then(function (response) {
      console.log(response.data);
      setPolicyType(response.data);
    });
  }
  useEffect(() => {
    readPolicyType();
  }, []);
  // office_company id,title,subtitle,icon,description,photo,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("content", value);
    formData.append("policy", policy);
    setLoading(true);
    // office_company id,title,subtitle,icon,description,photo,created_at
    axios.post(`${officesPTUrl}addP.php`, formData).then(function (response) {
      console.log(response.data);
      setValue("");
      setPolicy("");
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false); // Stop loading
    });
  };
  return (
    <div className="w-full">
      <Card>
        <form onSubmit={handleOnSubmit}>
          <div className="mb-2">
            <label>Policy For</label>
            <select
              value={policy}
              onChange={(e) => setPolicy(e.target.value)}
              className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
            >
              <option value=""></option>
              {/* <option value="0">ไม่ระบุ</option> */}
              {policyType.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.typename}
                </option>
              ))}
            </select>
          </div>
          <div className="my-2">
            <label>Document(PDF)</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          <div className="mb-2 h-screen">
            <ReactQuill
              modules={modules}
              theme="snow"
              value={value}
              onChange={setValue}
              className="h-4/5"
            />
          </div>
          {/*  title,subtitle,discription,titledownload,androiduser,iosuser */}

          <div className="flex justify-end">
            <button disabled={loading} type="submit" className="btnblue">
              {loading ? <>Please wait..</> : <>Save</>}
            </button>
          </div>
        </form>
        <Toaster />
      </Card>
    </div>
  );
};

export default AddPolicy;
