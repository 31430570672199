import React from "react";

const MyTooltip = ({ message, children }) => {
  return (
    <div className="group relative flex z-50">
      {children}
      <span className="absolute top-1 left-12 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
        {message}
      </span>
    </div>
  );
};

export default MyTooltip;
