import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { bankUrl } from "../../../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2";
import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

const ViewBank = () => {
  //bankUrl
  const [loading, setLoading] = useState(false);
  const [dataType, setDataType] = useState([]);

  function readTypeData() {
    axios.get(`${bankUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDataType(response.data);
    });
  }

  useEffect(() => {
    readTypeData();
  }, []);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${bankUrl}delete.php/${id}`).then(function (response) {
          console.log(response.data);
          readTypeData();
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };
  return (
    <div>
      {dataType.map((item, index) => (
        <div key={index} className="">
          <Card>
            <div className="flex flex-col gap-2 md:flex-row relative">
              <div className="w-full">
                <h2 className="font-semibold">
                  {item?.thName}({item?.enName})
                </h2>
              </div>
              <div className="mt-2 md:w-1/2 flex justify-end items-end absolute bottom-1 right-0">
                <button
                  // onClick={() => confirmDelete(item.id)}// bankname id,thName,enName,created_at
                  onClick={() => deleteData(item.id)}
                  className="bg-red-500 mr-4 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                >
                  <IoTrashOutline size={22} />
                </button>

                <Link
                  to={`editbank/${item.id}`}
                  className="mr-1 bg-blue-500 hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                >
                  <HiMiniPencilSquare size={22} />
                </Link>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ViewBank;
