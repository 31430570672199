import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import UploadImg from "../../assets/img/icons/upload.png";
import { blogUrl, imgBlogUrl } from "../../utils/baseUrl";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";

import Card from "./../Card";
const EditBlog = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [youtube, setYoutube] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSectedFile] = useState(null);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
    setFileName(URL.createObjectURL(e.target.files[0])); //.name
  };

  function readData(id) {
    axios.get(`${blogUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setTitle(response.data.title);
      setDescription(response.data.description);
      setYoutube(response.data.youtube);
    });
  }

  useEffect(() => {
    readData(id);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("youtube", youtube);
    formData.append("id", id);
    setLoading(true);

    axios.post(`${blogUrl}update.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
      // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
  };
  return (
    <div className="w-full ">
      <Card>
        <div className="grid grid-cols-12 gap-6">
          {/* ------  */}
          <div className="mb-6 bg-white p-8 rounded-xl col-span-12 md:col-span-6 xl:col-span-8 ">
            {/* ------ */}

            <div className="mb-6">
              <label
                htmlFor="productImage"
                className="w-full inline-block py-1 text-center hover:cursor-pointer"
              >
                {/* <p className="mb-2 text-base text-black">Upload Image</p> {`${imgUrl}${selectedFile}`}imgUrl*/}
                <div className="text-center">
                  {fileName !== null ? (
                    <>
                      <img
                        className={`w-full h-[${
                          windowSize[0] * 0.5
                        }px] rounded-xl object-cover`}
                        src={fileName}
                        alt=""
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className={`w-full h-[${
                          windowSize[0] * 0.5
                        }px] rounded-xl object-cover`}
                        src={`${imgBlogUrl}${datas?.photo}`}
                        alt=""
                      />
                    </>
                  )}
                </div>
                {/* <span className="text-center w-full inline-block mb-3">Image size must be less than 5Mb</span> */}
                <div className="">
                  <input
                    type="file"
                    onChange={handleOnChange}
                    id="productImage"
                    className="hidden"
                  />
                  Upload Image
                </div>
              </label>
            </div>
            {/* ----------- */}
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <p className="mb-0 text-base text-black">ชื่อเรื่อง/หัวข้อ</p>
                <input
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="input w-full h-[44px] rounded-sm border border-gray6 px-6 text-base"
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="mb-6">
                <p className="mb-0 text-base text-black">รายละเอียด</p>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="input rounded-sm border border-gray6 px-6 text-base h-[150px] w-full py-3 resize-none"
                  placeholder=""
                />
              </div>
              <div className="mb-6">
                <p className="mb-0 text-base text-black">Youtube</p>
                <input
                  name="title"
                  value={youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                  className="input w-full h-[44px] rounded-sm border border-gray6 px-6 text-base"
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit" className="btnblue">
                {loading ? <>กรุณารอ..</> : <>บันทึก</>}
              </button>
            </form>
            {/* ----------- */}

            {/* ------ */}
          </div>
          {/* ------  */}
        </div>
      </Card>
    </div>
  );
};

export default EditBlog;
